@import '../../theme/_theme.sass'

.title
	font-size: 36px
	line-height: 1.2
	margin: 16px 8px 16px 8px
	color: $Neutral800
	font-weight: 300
	>span
		font-size: 40px
		color: $Primary600
		margin-right: 8px
	>div
		display: block
	button
		margin-left: 32px
		@include primaryButton(16px, 40px)
		

.modal
	min-width: 80%
	& :global
		.ant-modal-close-x
			margin: 20px
		.ant-modal-header
			padding: 28px 48px
			border: none
	*
		font-family: SF Pro

.container
	width: 100%

.offerTable
	margin: 0 40px
	img
		width: 80%
		height: auto
		object-fit: contain
