@import '../../theme/_theme.sass'
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Ubuntu:wght@500&display=swap')

.important::after
  content: '*'
  color: $Red400

.header
  @include font-face-medium(22px)
  margin-bottom: 30px

.cardDetails
  @include font-face-medium(18px)
  margin-bottom: 40px
  background-color: $Neutral100
  width: 320px
  border-radius: 8px
  padding: 24px
  p
    font-family: SF Pro !important
    color:  $Neutral1000
    display: inline
    margin: 0
  
.cardLable
  margin-top: 8px
  display: block
  font-size: 12px
  color: $Neutral600
  line-height: 2

.illustration
  width: 100%
  margin: 0 auto

.cardStack
  width: 300px
  margin: 0 auto

.cardContainer
  @include font-face-medium(14px)
  position: relative
  text-align: left
  color: white
  margin-top: 56px
  img
    filter: drop-shadow(0px 10px 20px rgba(9,103,210,0.2))


.cardBalance
  position: absolute
  @include font-face-medium(32px)
  font-family: Ubuntu !important
  line-height: 24px
  opacity: 0.9
  color: white
  top: 32px
  left: 28px

.cardNumber
  position: absolute
  @include font-face-medium(20px)
  font-family: 'IBM Plex Mono', monospace
  letter-spacing: 1.1px
  font-weight: 600
  line-height: 1.2
  color: white
  top: 117px
  left: 28px

.cardName, .cardExp, .cardCVC
  position: absolute
  @include font-face-regular(14px)
  font-family: 'IBM Plex Mono', monospace
  line-height: 1.71
  color: white
  top: 155px
  left: 28px
.cardExp
  top: 155px
  left: 255px
.cardCVC
  left: 328px
.form
  margin-bottom: 50px
  label
    @include font-face-medium(14px)

.alert
  border-radius: 4px
  margin-bottom: 32px

.topupContainer
  border-radius: 8px
  border: solid 2px #7b8794
  padding: 24px 24px 0  24px
  width: 320px