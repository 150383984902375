@import '../../theme/_theme.sass'

.container
	display: block
	background-color: $Neutral100
	height: calc(100vh - 73px)
	overflow: auto
	width: 100%
	padding: 64px 50px
	*
		font-family: SF Pro
	> div
		max-width: 1400px
		margin-left: auto
		margin-right: auto

	& :global
		.ant-row
			width: 100%

.welcomeMessage
	@include font-face-medium(32px, $Primary1000)
	margin-bottom: 22px

.badges
	& :global
		.ant-badge
			margin-right: 12px
			padding: 6px 8px
			background-color: $PaleGrey
			border-radius: 4px
		.ant-badge-status-text
			b
				color: $Neutral1000
			@include font-face-semibold(14px, $Neutral600 )

.msgBubble
	width: 233px
	border-radius: 10px 10px 0 10px
	padding: 10.5px 10px
	box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15)
	background-color: $White
	line-height: 1.33
	@include font-face-medium(12px, $Neutral800)

.welcomePic
	background-color: $Yellow400
	width: 85px
	height: 85px
	border-radius: 50%
	overflow: hidden
	box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08)
	img
		height: 82px
		transform: translate(-24px, 2px)
	span
		display: block
		width: 20px
		height: 20px
		background-color: $Teal400
		border-radius: 50%
		opacity: 1
		position: absolute

.msgContainer
	padding-right: 20px
	display: flex
	align-items: end
	justify-content: end
	flex-direction: column
	button
		border-radius: 24px !important
		margin-top: 8px
		svg
			padding-right: 4px
		@include primaryButton(12px, 28px )

.iconsContainer
	border-radius: 24px
	margin: 20px auto
	background: $White
	padding: 24px 40px
	text-align: center
	>div
		cursor: pointer
	span
		display: block
		@include font-face-semibold(16px, $Neutral1000)
		max-width: 94px
		margin: auto
	img
		margin: auto
		display: block
		padding: 8px
		height: 70px
		width: 70px
		object-fit: contain
	>div
		&::after
			content: ''
			position: absolute
			top: 33px
			right: -21%
			display: block
			width: 51px
			height: 3px
			background-color: $Primary200
	>div:last-child
		&::after
			content: none

.completed
	>img, >span
		opacity: 0.4
	span:first-child
		opacity: 1 !important
		color: $Green
		font-size: 21px
		position: absolute
		left: 30px

.deadlines, .actionItems
	width: 100%
	margin-top: 24px
	h2
		@include font-face-semibold(22px)

.timeline
	max-height: 300px
	padding-top: 16px
	padding-right: 10px
	overflow-y: auto
	width: 100%
	& :global
		.ant-timeline-item-tail
			border-left: 1px dashed $Neutral400
			height: 100%
			top: -8px
		.ant-timeline-item-head
			top: 23px
			background-color: $Neutral400
			border-color: $Neutral400
		.ant-timeline-item-last
			padding-bottom: 0
			.ant-timeline-item-tail
				display: block

		.ant-timeline-item-content
			max-width: 634px
			>div
				padding: 0 20px
				background: $White
				height: 64px
				border-radius: 4px

.dDate
	height: 100%
	display: flex
	align-items: center
	border-right: 1px solid $Neutral200
	@include font-face-semibold(20px, $Neutral800)
	p
		margin: 0
	span
		line-height: 1
		display: block
		width: 100%
		@include font-face-medium(10px, $Primary500 )
	>div
		flex-flow: wrap

.dTitle
	display: flex
	padding-left: 20px
	align-items: center
	@include font-face-semibold(16px,	$Neutral800)
	p
		margin: 0
	>span
		margin-left: auto
		background-color: $Primary100
		padding: 0 8px
		border-radius: 24px
		@include font-face-medium(12px,	$Primary600)

.highlight
	*
		color: $Primary600 !important
	& :global
		.ant-timeline-item-head
			background-color: $Primary600
			border-color: $Primary600
		.ant-timeline-item-content
			>div
				border: 1px solid $Primary600 !important

.actionItems
	padding-left: 48px

.actionItemsContainer
	display: block !important
	width: 100%
	max-height: 300px
	overflow-y: auto
	padding-right: 10px

.actionItem
	max-width: 614px !important
	background-color: $White
	margin-bottom: 4px
	border-radius: 4px
	border: solid 1px $Neutral200
	p
		margin: 0
		padding-left: 16px
		align-self: center
		@include font-face-semibold(16px, $Neutral800)
	a
		align-self: center
		margin-left: auto
	&[aria-label="urgent"]
		.aTitle
			>div>span
				background-color: $Red100
		border-color: $Red500
		span, p
			color: $Red500
		button
			background-color: $Red500
			border-color: $Red500
			>span
				color: $White

.pMedium, .pHigh, .pLow, .pUrgent
	min-height: 72px
	padding: 12px 0 8px 0
	justify-content: center
	font-size: 24px
	border-right: 1px solid $Neutral200
	span
		line-height: 1
		word-spacing: 100px
		width: 100%
		text-align: center
		@include font-face-medium(10px, $Red500)

.pHigh, .pUrgent
	color: $Red500

.pUrgent
	padding-top: 22px
	svg
		border-radius: 100%
		border: 1px solid $Red500

.pMedium
	color: $Orange500
	span
		color: $Orange400
.pLow
	color: $Yellow500
	span
		color: $Yellow600

.downloadB
	margin-left: auto
	margin-right: 26px
	@include font-face-semibold(14px, $Primary600)

.aTitle
	width: 100%
	height: 100%
	button
		@include primaryButton(16px, 32px)
		padding: 4px 12px
		& :global
			.anticon
				font-size: 14px
				margin-left: 2px
	>div>span
		align-self: top
		text-transform: uppercase
		margin-left: 16px
		background-color: $Primary100
		padding: 4px 8px
		border-radius: 24px
		@include font-face-bold(8px,	$Primary600)
		line-height: 18px