@import '../../theme/_theme.sass'

.chatButton
  color: #a065cb
  background-color: white
  border-color:  #BCA1CF
  padding: 0 12px
  margin: 0 12px
  border-radius: 4px
  border-width: 2px
  height: 42px
  font-weight: 600
  &:hover
    background-color: #a065cb
    border-color: white