@import '../../theme/_theme.sass'
@import './VisaTranscriptsList.module'

.modal
  font-family: SF Pro
  min-width: 920px

  & :global
    .ant-modal-close-x
      margin: 10px
    .ant-modal-header
      padding-bottom: 0
    .ant-modal-body
      padding: 30px 64px
  *
    font-family: SF Pro

.title
  font-size: 20px
  line-height: 1.2
  margin: 16px 8px 16px 8px
  color: $Neutral800
  font-weight: 600
  > span
    font-size: 24px
    color: $Primary600
    margin-right: 8px

.hitlist
  width: 100%
  max-height: 450px
  overflow-y: auto
  border: solid 2px $Neutral200
  padding: 30px 28px
  li
    border: none !important
  & :global
    .ant-list-item
      padding: 0 0 16px 0 !important

.hitRow
  width: 100%

.hitText
  font-size: 14px
  font-weight: 500
  line-height: 1
  display: flex
  align-items: center
  padding-left: 10px
  color: $Neutral1000

.transInfo
  margin-bottom: 16px
  svg
    vertical-align: -0.1em
.visaAccepted
  margin-left: 12px
  display: inline-flex

.unmatch
  svg,span
    color: $Neutral400

.footer
  margin-top: 32px
  @include font-face-semibold(14px, $Neutral800)
  button
    margin-left: 16px
    @include primaryButton(14px, 36px)
    
