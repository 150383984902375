@import '../../theme/_theme.sass'

.container
	margin-top: 36px
	width: 100%

.steps
	+gradlySteps

.awaiting
	margin-top: -48px
	width: 100%
	font-family: SF Pro
	h1,h2
		color: $Neutral1000
	h1
		margin-top: 24px
		font-size: 32px
		font-weight: 600
	h2
		font-size: 16px
		font-weight: normal
	>div>span
		background-color: $Primary100
		border-radius: 24px
		@include font-face-semibold(18px, $Primary600)
		padding: 8px 12px
	img
		width: 28rem
