@import './theme/_theme.sass';
@import 'animate.css';

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Medium.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-ExtralightItalic.woff2') format('woff2'),
    url('./assets/fonts/Graphik-ExtralightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/Graphik-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Regular.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-SemiboldItalic.woff2') format('woff2'),
    url('./assets/fonts/Graphik-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik Super';
  src: url('./assets/fonts/Graphik-Super.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Super.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Black.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/Graphik-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/Graphik-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-ThinItalic.woff2') format('woff2'),
    url('./assets/fonts/Graphik-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-BlackItalic.woff2') format('woff2'),
    url('./assets/fonts/Graphik-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-RegularItalic.woff2') format('woff2'),
    url('./assets/fonts/Graphik-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Thin.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Bold.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Light.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Super';
  src: url('./assets/fonts/Graphik-SuperItalic.woff2') format('woff2'),
    url('./assets/fonts/Graphik-SuperItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Semibold.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('./assets/fonts/Graphik-Extralight.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Extralight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SFProDisplay-Medium.eot');
  src: local('SF Pro Medium'), local('SFProDisplay-Medium'),
    url('./assets/fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/SFProDisplay-Medium.woff2') format('woff2'),
    url('./assets/fonts/SFProDisplay-Medium.woff') format('woff'),
    url('./assets/fonts/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SFProDisplay-RegularItalic.eot');
  src: local('SF Pro Regular Italic'), local('SFProDisplay-RegularItalic'),
    url('./assets/fonts/SFProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/SFProDisplay-RegularItalic.woff2') format('woff2'),
    url('./assets/fonts/SFProDisplay-RegularItalic.woff') format('woff'),
    url('./assets/fonts/SFProDisplay-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SFProDisplay-Bold.eot');
  src: local('SF Pro Bold'), local('SFProDisplay-Bold'),
    url('./assets/fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/SFProDisplay-Bold.woff2') format('woff2'),
    url('./assets/fonts/SFProDisplay-Bold.woff') format('woff'),
    url('./assets/fonts/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SFProDisplay-Semibold.eot');
  src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
    url('./assets/fonts/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/SFProDisplay-Semibold.woff2') format('woff2'),
    url('./assets/fonts/SFProDisplay-Semibold.woff') format('woff'),
    url('./assets/fonts/SFProDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SFProDisplay-Regular.eot');
  src: local('SF Pro Regular'), local('SFProDisplay-Regular'),
    url('./assets/fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
    url('./assets/fonts/SFProDisplay-Regular.woff') format('woff'),
    url('./assets/fonts/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SFProDisplay-BoldItalic.eot');
  src: local('SF Pro BoldItalic'), local('SFProDisplay-BoldItalic'),
    url('./assets/fonts/SFProDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/SFProDisplay-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/SFProDisplay-BoldItalic.woff') format('woff'),
    url('./assets/fonts/SFProDisplay-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SFProDisplay-Light.eot');
  src: local('SF Pro Light'), local('SFProDisplay-Light'),
    url('./assets/fonts/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/SFProDisplay-Light.woff2') format('woff2'),
    url('./assets/fonts/SFProDisplay-Light.woff') format('woff'),
    url('./assets/fonts/SFProDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Graphik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  scrollbar-width: thin;
}

.app {
  div {
    &:not([class*='ant']) {
      display: flex;
    }
  }
}

.logo {
  font-family: 'Sofia Pro';
  font-size: 48px;
  font-weight: 800;
  color: $Primary;
}

h1 {
  color: $GreyDarker;
}

h2 {
  color: $GreyDarker;
}

h3 {
  color: $GreyDarker;
  font-weight: 400;
}

h4 {
  color: $Grey;
}

h5 {
  color: $GreyLight;
}

.ant-progress-inner {
  background-color: $Neutral200 !important;
}

.ant-popover-inner-content {
  padding: 0 !important;
}

.all-disabled {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.full-width {
  width: 100%;
}

* ::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  background: $Neutral200;
}

* ::-webkit-scrollbar-thumb {
  background-color: $Neutral400; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid $Neutral400; /* creates padding around scroll thumb */
}

@-webkit-keyframes rotate {
  from {
    opacity: 1;
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 720deg);
    transform: rotate3d(0, 0, 1, 720deg);
    opacity: 1;
  }
}
@keyframes rotate {
  from {
    opacity: 1;
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 720deg);
    transform: rotate3d(0, 0, 1, 720deg);
    opacity: 1;
  }
}
.animate__rotate {
  -webkit-animation-name: rotate;
  animation-name: rotate;
  -webkit-transform-origin: center;
  transform-origin: center;
}

.ant-btn-primary:hover{
  filter: brightness(100%) !important
}



