@import '../../theme/_theme.sass'

.important::after
	content: '*'
	color: $Red400

.header
	margin-bottom: 30px
	@include font-face-medium('18px')

.illustration
	width: 90%
	margin: 36px auto

.form
	font-family: SF Pro
	label
		@include font-face-medium(16px)
	input
		width: 320px
		height: 40px
		&::placeholder
			@include font-face-regular(16px, $Neutral500)			
	button
		border-radius: 4px
		font-weight: 600
	& :global
		.ant-form-item-explain-error
			@include font-face-regular(16px, $Red600)
			margin: 12px 0 24px
			line-height: 22px
			width: 100%

.dollarsign
	font-size: 28px
	margin-bottom: 12px
 