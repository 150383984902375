@import '../../theme/_theme.sass'
.help
	p
		width: 100%
		text-align: center
		margin: 32px 0 16px
		@include font-face-semibold(14px, $Neutral800)
		line-height: 20px
	button
		@include primaryButton(14px, 36px)
		margin-bottom: 32px
	