@import '../../theme/_theme.sass'

.important::after
  content: '*'
  color: $Red400

.container
  background-color: $Neutral100
  height: calc(100vh - 73px)
  width: 100%
  padding: 24px 48px
  flex-direction: column
  .childContainer
    width: 100%
    max-width: 1088px
    margin: 32px auto
    flex-direction: column
    .accordion
      width: 100%
      a
        text-decoration: underline
        color: unset