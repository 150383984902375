@import '../../theme/_theme.sass'

.title
	@include font-face-semibold(20px, $Neutral800)
	img
		margin: 16px 16px 16px 0
		height: 32px
		width: auto
		object-fit: contain
	
.iconsContainer
	max-width: 70%
	height: 124px
	margin-top: 12px
	div
		flex-direction: column
		text-align: center !important
		align-items: center
		p
			@include font-face-semibold(36px, $Primary600)
			text-align: center
			margin: 0
			margin-top: 16px
			line-height: 24px
	svg
		color: $Neutral800
		width: auto
		height: 28px

.modal
	min-width: 700px
	& :global
		.ant-modal-close-x
			margin: 10px
	*
		font-family: SF Pro

.container
	width: 100%
	>div
		width: 90%
	button
		border-radius: 4px
		padding: 6px 22px
		line-height: 1
		margin-left: 20px

.tnc
	border: solid 1px $Neutral400
	min-height: 350px
	overflow-x: hidden

.detailsContainer
	margin: 32px 0 24px
	h3
		margin-top: 28px
		font-size: 18px
		color: $Black
		font-weight: 500
	p
		font-size: 16px
	span:last-child
		@include font-face-semibold(18px)
	& :global
		.ant-checkbox
			top: 0.4em
		.ant-checkbox-inner
			height: 20px
			width: 20px
			border-color: $Neutral1000
			&::after
				height: 12px
				top: 45%