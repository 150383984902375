@import '../../theme/_theme.sass'

[id$=".big_play_button_background"]
  background-color: white!important
  width: 64px!important
  height: 62px!important
  left: 40%!important
  border-radius: 100px
  box-shadow: 1px 1px 2px

[id$=".big_play_button_graphic"]
  filter: invert(1)
  background-size: 64px 45px!important
  height: 64px!important
  width: 64px!important
  background-position: 2px 9px!important
  left: 40%!important

.separator
  width: 100%
  height: 1px
  background-color: $Neutral200
  margin-top: 8px
  margin-bottom: 8px

.incompleteContainer
  margin-top: 24px
  margin-bottom: 48px
  .incompleteContent
    display: block!important
    @include font-face-regular(14px)
  img
    margin-left: 64px

.processingContainer
  margin-top: 24px
  margin-bottom: 24px
  .content
    @include font-face-regular(14px)
    flex-direction: column
    flex: 0.5
    align-items: flex-start
    ul
      li
        color: $Primary600
        cursor: pointer
    .watchButton
      margin-top: 96px
      margin-bottom: 16px
  .videoCarousel
    flex: 0.4
    height: 214px
    margin-left: auto
    border-radius: 4px
    margin-bottom: 24px
    overflow: hidden
    flex-direction: column
    .description
      padding-top: 24px
      padding-left: 8px
      padding-right: 8px
      padding-bottom: 8px
      flex-direction: column
      z-index: 1
      background: linear-gradient(0, black, transparent)
      .title
        @include font-face-semibold(14px, white)
      .date
        @include font-face-regular(12px, white)

.feedbackContainer
  flex-direction: column
  width: 100%
  .description
    margin-top: 8px
  .feedbackContent
    @include font-face-regular(14px)
    border: 1px solid $Neutral300
    border-radius: 3px
    padding: 16px
    display: block!important
    margin-bottom: 32px
    max-height: 56vh
    overflow: auto
    .highlighted
      background-color: $Yellow200
      cursor: pointer
  .submitButton
    margin-bottom: 40px
    align-self: flex-start

.popoverContainer
  display: flex
  flex-direction: column
  background: white
  width: 300px
  .popOverTitle
    @include font-face-semibold(14px)
    margin-left: 16px
    margin-top: 8px
  .htmlContent
    @include font-face-regular(12px)
    overflow-y: scroll
    margin-left: 8px
    margin-right: 8px
  .commentContainer
    background-color: $Neutral100
    margin-top: 16px
    .heading
      padding: 8px
      display: flex
      flex-direction: row
      align-items: center
      .icon
        font-size: 14px
      .title
        @include font-face-semibold(12px)
        margin-left: 8px
    .comment
      display: flex
      flex-direction: row
      padding: 8px
      .avatar
        min-height: 32px
        min-width: 32px
      .description
        margin-left: 16px
        .name
          @include font-face-semibold(12px)
        .content
          @include font-face-regular(12px)
          overflow-y: scroll
  .buttonsContainer
    display: flex
    flex-direction: row
    align-self: stretch
    margin: 8px
    &>*
      flex: 1
    .readMoreButton
      margin-left: 8px
    .markedForHelp
      display: flex
      align-items: center
      flex: 1.5
      .markIcon
        font-size: 16px
        line-height: 20px
        color: $Neutral500
      .text
        @include font-face-semibold(12px, $Neutral500)
        margin-left: 4px


