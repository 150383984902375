@import '../../theme/_theme.sass'

.steps
	+gradlySteps
	padding: 8px 52px
	margin-bottom: 64px
	& :global
		.ant-steps-item-title
			font-weight: 600
		.anticon
			line-height: 37px
		.ant-steps-icon
			vertical-align: inherit !important
		+sm
			.anticon
				height: 14px
				font-size: 14px
			.ant-steps-item-icon
				margin: 0 !important
			.ant-steps-icon
				font-size: 14px !important
			.ant-steps-item-title
				@include font-face-medium(14px, $White)
				padding-right: 12px !important
			.ant-steps-item-content
				min-height: unset !important
			.ant-steps-item-active
				margin-bottom: 4px
				background-color: $Primary500
				border-radius: 24px
				*
					color: white !important
	+sm
		margin-bottom: 32px
		flex-direction: row
		border: none
		padding: 0
		width: 100%
		overflow-x: auto
		overflow-y: hidden


.form
	overflow: hidden
	input
		border-color: $Neutral300
		border-radius: 4px
	button
		@include primaryButton(16px, 40px)
		font-weight: 400
		margin-top: 40px
	& :global
		.ant-select, .ant-select-selector, .ant-picker
			border-radius: 4px !important
		.ant-slider-mark-text
			max-width: 80px
		.ant-form-item-label
			label
				height: unset !important
				line-height: 24px
				@include font-face-medium(16px)
				&::before
					position: absolute
					right: -14px
					@include font-face-bold(16px, $Red600)

.formContainer
	width: 100%
	max-width: 80vw
	overflow: auto
	margin: auto
	+sm
		padding-left: 4px
		& :global
			.ant-col
				flex-basis: 100%

.noMark
	label::before
		content: none !important

.label
	display: inline-block !important
	flex-direction: column
	margin-bottom: -4px
	text-align: left

.subLabel
	line-height: 20px
	@include font-face-regular(14px, $Neutral800)
	margin-bottom: 12px

.requiredMark
	display: inline-block !important
	@include font-face-bold(16px, $Red600)
	line-height: 18px
	
.payment
	border-radius: 4px
	margin: 0 auto !important
	padding: 0 48px 64px
	> div		
		line-height: 1
	& :global
		.ant-skeleton
			width: 50%
			margin: 12px auto
	+sm
		width: 100%
		padding: 0
	+lg
		width: 100%

.planInfo, .yourInfo
	height: 100%
	display: inline-flex
	padding: 32px
	border-radius: 16px
	text-align: center
	flex-direction: column
	background-color: $Neutral100
	h3
		@include font-face-bold(14px, $Primary600)
		margin-bottom: 32px
		line-height: 24px
		span
			vertical-align: sub
			font-size: 18px
.userDetails
	width: fit-content
	text-align: left
	margin: 0 auto
	@include font-face-semibold(14px, $Neutral1000)
	line-height: 20px
.detailLabel
	@include font-face-medium(14px, $Neutral800)


.planInfo
	> span
			display: block
			@include font-face-bold(16px, $Teal800)
	> h1
		@include font-face-bold(24px, $Neutral1000)
		margin: 12px 0 4px 0
	> h2
		@include font-face-semibold(16px, $Neutral800)
		margin: 0 0 32px 0
	+sm
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.05)
		background-color: $Neutral100
		border-radius: 12px
		padding: 24px
		> span
			@include font-face-semibold(16px, $Primary600)
		> h1
			@include font-face-bold(16px)
		> h2
			@include font-face-semibold(14px, $Neutral800)
			padding-bottom: 8px
			margin: 0 0 16px 0
			border-bottom: 1px solid $Neutral300

	
.processingDeposit
	margin: 24px auto
	h1
		@include font-face-bold(20px, $Neutral1000)
		line-height: 28px
		text-align: center
		margin-top: 12px
	+sm
		padding-top: 12px
		h1
			@include font-face-bold(14px, $Neutral800)
		svg
			font-size: 24px

.paymentFail
	margin-top: 24px
	display: inline-block
	text-align: center
	@include font-face-medium(14px, $Neutral1000)
	h2
		@include font-face-medium(14px, $Neutral800)
	button
		margin: 4px 0
	+sm
		margin-top: 0
		font-size: 12px
		button
			font-size: 12px

.radioGroup
	display: inline-flex
	label
		min-width: 184px
		height: max-content !important
		border: 1px solid $Neutral300
		padding: 8px
		border-radius: 4px
	& :global
		.ant-radio-wrapper-checked
			border-color: $Primary600
	+sm
		display: block
		label
			margin: 8px 0
			width: min-content

.depositConfirmed
	margin-top: 24px
	height: 100%
	justify-content: center
	>div
		width: 100%
	svg
		font-size: 48px
		margin: auto 0
		color: $Teal800
	h1
		margin: 0 12px 0
		@include font-face-semibold(32px, $Neutral1000)
	+lg
		margin-top: 0
		h1
			font-size: 18px
			margin: 0 6px 0
		svg
			font-size: 24px
		
	
.liReasons
	list-style: none
	padding: 0
	margin: 0
	li
		@include font-face-medium(14px, $Neutral800)
		position: relative
		line-height: 22px
		margin: 0 12px 4px 24px
		span
			left: -24px
			color: $Teal600
		svg
			padding: 1px
			margin-top: 2px
			width: 14px
			height: 14px
	+sm
		li
			font-size: 12px
			line-height: 16px
			margin: 0 12px 4px 16px
			span
				left: -16px
			svg
				width: 12px
				height: 12px
				margin-top: 1px
				margin-left: -1px

.termLabel
	display: inline-block !important
	@include font-face-semibold(15px, $Neutral1000)

.termSubLabel
	display: block
	@include font-face-regular(12px, $Neutral800)
	line-height: 1.2

.sliderForm
	& :global
		.ant-form-item-control-input
			padding: 0 48px

.terms
	max-width: 800px
	ol
		border-bottom: 1px solid $Neutral300
		padding: 24px 32px
	li
		margin-bottom: 8px

.termsButtons
	margin-top: 8px
	gap: 12px

.agree
	margin-left: auto
	@include primaryButton(14px, 32px)

.decline
	@include defaultButtonWithFill(14px, 32px)
	background: white

.planDetails
	& :global
		.ant-form-item-extra
			@include font-face-medium(14px, $Primary600)
			line-height: 20px
			margin-top: 8px

.proceedToPay
	flex-direction: column
	width: max-content
	margin: 32px auto 0
	button
		margin: 4px 0

.editApp
	width: 100%
	@include defaultButtonWithFill(16px, 40px)
.paynow
	width: 100%
	@include primaryButton(16px, 40px)

.retry
	width: 100%
	@include dangerButton(16px, 40px)