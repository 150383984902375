@import '../../theme/_theme.sass'

.container
	width: 100%
	font-family: SFPro

.headerContainer
	width: 100%
	padding: 0 24px
	text-align: center
	font-size: 12px
	font-weight: 600
	font-stretch: normal
	font-style: normal
	color: $Neutral600
	line-height: 1
	letter-spacing: 0.56px

.cards
	width: 100%
	border-radius: 8px
	// box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15)
	border: solid 2px $Neutral200
	margin: 24px 0 0 0
	height: 128px
	& :global
		.ant-card-body
			display: flex
			align-items: center
			height: 100%

.row
	width: 100%
	height: 100%
	text-align: center
	font-size: 24px
	font-weight: 600
	font-stretch: normal
	font-style: normal
	line-height: 1
	letter-spacing: normal
	color: $Neutral1000
	>div>span
		color: $Primary600
		font-size: 18px
		padding-left: 12px
		text-align: left
		display: block
	button
		@include primaryButton(12px, 32px)
