@import '../../theme/_theme.sass'

.form
	margin-top: 36px
	width: 100%
	font-family: SF Pro
	ol
		list-style: none
		padding-left: 0
	label, ol
		@include font-face-medium(18px, $Neutral800)
		line-height: 1.22
		width: 100%
		&::after
			content: none
	label > span
		margin: 0 5px
		cursor: pointer
	button
		border-radius: 4px
		padding: 5px 16px
		font-size: 12px
		line-height: 1.66
		font-weight: normal
		font-stretch: normal
		font-style: normal
	button[type=submit]
		@include primaryButton(16px, 44px)
		width: 126px
	h1
		@include font-face-bold(18px, $Neutral1000)
		margin-bottom: 8px
	h2
		@include font-face-medium(18px, $Neutral600)
		margin-bottom: 24px
	h1,h2
		line-height: 24px

	& :global
		.ant-form-item-label
			p
				@include font-face-regular(16px, $Neutral600)
		.ant-radio-wrapper-checked
			color: $Primary600
		.ant-upload-list-item-card-actions-btn
			opacity: 1
			.anticon
				color: $Red600
		.ant-upload-list-item
			width: fit-content
			max-width: 300px
		.ant-form-item-explain-error
			font-family: SF Pro
			color: $Orange700
			font-size: 14px
			font-weight: normal
			margin: 8px 0 14px 0
		.ant-form-item-has-error
			button
				border-color: $Orange700
				color: $Orange700
				&:hover
					border-color: $Orange700
					color: $Orange700
				&:active
					border-color: $Orange700
					color: $Orange700
				&:focus
					border-color: $Orange700
					color: $Orange700
.labelDiv
	display: inline !important
	> span
		color: $Primary600
		font-size: 16px
		margin: 0 8px
		cursor: pointer
		>svg
			height: 100%
