@import '../../theme/_theme.sass'
  
.onboarding-container
  font-family: SF Pro
  margin-top: 32px
  margin-left: auto
  margin-right: auto
  flex-direction: column
  align-self: center
  width: 80%
  overflow: auto
  .title
    font-size: 22px
    font-weight: 400
    margin-bottom: 16px
  .subtitle
    font-size: 16px
    margin-bottom: 16px
    margin-top: 12px
  .marker
    width: 3px
    border-radius: 10px
    height: 32px
    margin-right: 12px
    background-color: #B12C3DCC
  .callout
    padding: 10px 20px
    background-color: rgba(237, 218, 49, 0.2)
    margin-bottom: 24px
    border-radius: 4px
  input
    max-width: 500px
  .onb-submit
    width: 180px
    margin-top: 24px
    @include primaryButton(16px, 40px)
  label
    font-weight: 500