@import '../../theme/_theme.sass'

.calendar
	width: 80%
	font-family: SF Pro
	margin-top: 48px
	border-radius: 14px
	border: solid 2px $Neutral400
	table
		padding: 32px
	td
		width: 42px
		&::before
			width: 42px
	thead
		color: $Neutral800
		font-size: 12px
		font-weight: 500
		line-height: 1
		letter-spacing: 0.36px
	& :global
		.ant-picker-panel
			@include noselect
			padding: 10px !important
			border-radius: 14px
		.ant-picker-cell-inner
			background: transparent
		.ant-picker-cell-in-view
			pointer-events: auto
			position: relative
			font-size: 18px
			font-weight: normal
			line-height: 1.22
			letter-spacing: normal
			color: $Neutral1000
		.ant-picker-panel
			border-top: none
		.ant-picker-cell-disabled
			color: $Neutral300
			cursor: unset
			&::before
				background-color: rgba(0, 0, 0, 0)
		.ant-picker-cell-selected
			font-weight: bold
			color: $Primary600
			> div.ant-picker-cell-inner
				height: 36px
				width: 36px
				background: transparent
				color: $Primary600
				&::before
					border: none
			&::before
				border-radius: 50px !important
				border: 2px solid $Primary600
				width: 36px
				height: 36px
				transition: all 0.1s
				transform: translate(20%, -50%)

.calHeader
	padding: 12px
	width: 100%
	font-size: 20px
	font-weight: 600
	font-stretch: normal
	font-style: normal
	line-height: normal
	letter-spacing: normal
	color: $Neutral800
	&::after
		content: ""
		background: $Neutral200
		position: absolute
		top: 105px
		left: 6%
		width: 65%
		height: 1px

.slots
	width: 100%
	margin-top: 24px
	button
		font-size: 12px
		font-weight: 500
		color: $Neutral800
	*
		font-family: SF Pro
	& :global
		.ant-btn:focus
			font-weight: bold
			border-width: 2px
