@import '../../theme/_theme.sass'
.container
	width: 100%
	font-family: SF Pro
	h1, > div > span
		margin: 0 0 8px 0
		display: block
		width: 100%
		text-align: center
		@include font-face-semibold(32px, $Primary600)
	h1
		@include font-face-medium(32px, $Primary600)
		line-height: 20px
		margin-bottom: 36px
	h3
		margin: 0
		@include font-face-medium(14px, $Neutral400)
	> div > span
		@include font-face-semibold(16px, $Neutral800)
	> div> img
		width: 100%
		height: 38px
		object-fit: contain
		margin: 24px 0
	+lg
		h3, h1
			display: none
		> div> img
			margin: 0

.ageInput
	margin-left: 8px

.periodRadio
	margin: 12px auto
	border: 1px solid $Neutral200
	border-radius: 34px !important
	padding: 4px
	label
		margin-left: 12px
		border: none !important
		border-radius: 34px !important
		width: 110px
		text-align: center
		&:first-child
			margin-left: 0
	& :global
		.ant-radio-button-wrapper
			&::before
				content: none !important
	+lg
		margin: 0
		label
			width: 95px
			margin-left: 6px

.planDetails
	border: 2px solid $Neutral200
	border-radius: 16px
	height: 120px
	@include box-shadow(2px, 8px)
	img
		display: block
		padding-top: 10px
		margin: auto
		width: 75px
		object-fit: contain
	h1
		text-align: left
		margin: 0
		@include font-face-semibold(22px, $Primary600)
	ul
		text-align: center
		list-style: none
		margin: 0
		padding: 0
		border-left: 1px solid $Neutral200
		border-right: 1px solid $Neutral200
		@include font-face-semibold(12px, $Neutral800)
		span
			line-height: 1 !important
			@include font-face-semibold(12px, $Primary600)
		svg
			margin-left: 4px
	+lg
		background-color: $Neutral100
		height: 306px
		padding: 24px
		img
			display: none
		h1
			display: inline
			font-size: 16px
			line-height: 24px
		ul
			text-align: left
			border: none
			border-top: 1px solid $Neutral200
			padding-top: 16px
			span
				color: $Neutral800
	
.premium, .premiumPeriod
	width: 100%
	text-align: center
	padding-left: 14px
	display: block
	line-height: 22px !important
	@include font-face-semibold(22px, $Primary700)
	+lg
		@include font-face-semibold(16px)
		display: inline-block
		text-align: left
		padding: 0 8px 0 0
		width: max-content

.premiumPeriod
	position: absolute
	@include font-face-semibold(12px, $Neutral600)
	+lg
		@include font-face-medium(10px)
		position: relative
		vertical-align: super

.planButtons
	button
		display: block
		margin: 4px 14px
		flex-basis: 100%
		border-radius: 4px !important
		padding: 0 !important
		@include primaryButton(14px, 36px)
		&:last-child
			@include defaultButtonWithFill(14px, 36px)
			background: white
		+lg
			margin: 4px 0
	+lg
		margin-top: 24px

.plansContainer
	margin-top: 32px

.compareParams
	width: 60%
	font-size: 14px
	line-height: 1
	font-weight: 500
	color: $Black
	margin: 12px 0 24px 0
	> div
		display: flex
		align-items: center
		justify-content: center
	span
		font-size: 20px
		color: $Primary600
		margin-right: 6px
	+lg
		align-items: center
		justify-content: center
		width: 100%
		margin: 32px 0
		> div
			align-items: left
			justify-content: left

.help
	margin: 24px 0
	p
		@include font-face-medium(14px, $Primary600)
		line-height: 32px
		margin-right: 12px
	button
		@include primaryButton(14px, 32px )
	+lg
		display: none

.recommended
	border: solid 2px $Teal600
	&:hover
		border-color: $Teal600
	&::after
		content: '★ Top Choice'
		color: $White
		@include font-face-semibold(12px, $White )
		font-family: SF Pro
		line-height: 20px
		position: absolute
		top: 10px
		left: -40px
		padding: 2px 10px
		background-color: $Teal600
		border-radius: 4px