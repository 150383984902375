@import '../../theme/_theme.sass'

.cardContainer
  font-family: SF Pro
  padding: 0 10px 0 40px
  max-height: 630px
  overflow: auto
  >div
    height: 160px
    &:first-child
      margin-top: 0
    &:last-child
      margin-bottom: 12px
  & :global
    .ant-card-body
      padding: 16px 24px
    .ant-row
      width: 100%
    .ant-progress-inner
      background-color: white !important

.percentage
  @include font-face-semibold(16px, $Neutral800)
  line-height: 1
  span
    @include font-face-medium(10px, $Neutral800)
    line-height: 1
    
.transInfo
  text-align: start
  @include font-face-medium(14px, $Neutral800)
  line-height: 20px
  span
    @include font-face-bold(14px, $Primary600)
    display: inline-block
    padding: 0
  svg
    margin-right: 6px
    color: $Teal600

.unmatch
  opacity: 0.5
  span
    color: $Neutral1000
  svg
    color: $Neutral400

.visaAccepted
  border-radius: 4px
  height: 28px
  padding: 0 8px
  align-items: center
  @include font-face-semibold(14px, $Teal700)
  background-color: $Teal100
  margin-bottom: 8px
  svg
    width: 14px
    height: auto
    margin: 0 4px

.progress
  max-width: 134px
  & :global
    .ant-progress
      align-self: center
      margin: 0 auto
      justify-self: center

.visaRejected
  @extend .visaAccepted
  color: $Red600
  background-color: $Red100


   
