@import '../../theme/_theme.sass'

.container
  background-color: $Neutral100
  height: calc(100vh - 73px)
  overflow: auto
  width: 100%
  padding: 24px 48px
  flex-direction: column
  .childContainer
    width: 100%
    max-width: 1088px
    margin: 32px auto
    flex-direction: column
.requestButton
  background-color: $Primary
  color: $White
  font-weight: 600
  margin-top: 30px
  height: 46px
  border-radius: 6px
  width: 25vw

.requestButton:hover
  background-color: $White
  color: $Primary
  border: 3px solid inner $Primary

.welcomeScreenContainer
  overflow: auto
  display: block !important
  height: 87vh
  max-width: 1344px
  width: 100%
  margin: 0 auto
  margin-top: 24px
  background-color: white
  font-family: SF Pro
  line-height: 1
  ul
    text-align: center
    align-items: middle
    li
      font-weight: bold
    li:nth-child(1)
      font-size: 12px
      color: $Neutral800
      span
        font-weight: normal
        color: $Neutral800
        font-size: 12px

.welcomeBanner
  width: 1022px
  margin: 96px auto 80px auto
  button
    @include primaryButton(16px, 44px)
    width: 164px

.iconsContainer
  width: 70%
  height: 136px
  div
    padding: 0 62px
    text-align: center
    flex-direction: column
    align-items: center
    p
      @include font-face-semibold(20px, $Neutral800)
      margin: 0
      margin-top: 16px
      line-height: 24px
  svg
    height: 64px
    color: $Primary500

.ratesContainer
  width: 772px
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  margin: 80px 88px 12px 90px
  gap: 8px
  padding: 0
  >div
    display: flex
  h1
    @include font-face-bold(30px)
    margin: 0
  button
    align-self: flex-end
    display: inline
    @include font-face-semibold(14px, $Primary600)
    border: 2px solid $Primary600
    border-radius: 24px
    height: 36px
    &:hover
      background-color: $Primary600
      color: $White

.leftUL
  list-style: none
  width: 450px
  z-index: 2
  height: 100%
  flex-grow: 0
  margin: 0
  padding: 0
  background-color: $Neutral100
  @include box-shadow-20-40()
  li > div
    height: 88px
    font-size: 16px
    img
      height: 40px
      object-fit: contain
    span
      color: $Orange700
      font-size: 12px
      display: block
      margin-top: 4px
      font-weight: bold

  li:nth-child(1)
    height: 74px
    p
      margin-bottom: 4px
      font-size: 14px
  li:nth-child(2)
    background-color: $Primary900
    color: white
    img
      margin-left: -30px
  li:nth-child(n+ 3)
    &::after
      content: ''
      display: block
      position: relative
      bottom: 0
      width: 88%
      left: 12%
      overflow: hidden
      border-top: 1px solid $Neutral200
  li:last-child
    &::after
      content: none

.welcomeScreen
  height: calc(100vh - 73px)
  width: 100%
  background-color: $Neutral100

.rightUL
  list-style: none
  width: 300px
  height: 98%
  flex-grow: 0
  margin: auto
  margin-left: 0
  padding: 0
  background-color: $Neutral100
  @include box-shadow-20-40()
  li > div
    height: 88px
    font-size: 14px
    align-items: center !important
    display: flex
    img
      height: 30px
      object-fit: contain

  li:nth-child(1)
    height: calc(74px - 1%)
    p
      margin-bottom: 4px
      font-size: 14px
      line-height: 1

  li:nth-child(2)
    background-color: $Primary1000
    color: white

  li:nth-child(n+ 3)
    &::after
      content: ''
      display: block
      position: relative
      bottom: 0
      width: 88%
      right: 12%
      overflow: hidden
      border-top: 1px solid $Neutral200
  li:last-child
    &::after
      content: none

.tableContainer
  height: max-content

.redDot
  height: 7px
  width: 7px
  margin: 0 0 2px 4px
  background-color: $Orange700
  border-radius: 50%
  display: inline-block

.cheapestTag
  position: relative
  margin-left: -30px
  margin-top: 8px
  float: left
  border-radius: 4px
  padding: 0 10px
  background-color: #2fd06e
  font-size: 8px !important
  font-family: SF Pro
  span
    color: white
    font-size: 8px
    line-height: 20px
    margin: 0 3px 0 0
  span:nth-child(1)
    margin-top: 1px

.compareButton
  @include defaultButtonWithFill(16px, 44px)
  background-color: $White !important

.header
  display: inline
  h2
    display: inline
    @include font-face-bold(36px)
    line-height: 42px
    margin: 0
    vertical-align: middle
  img
    height: 42px
    width: 42px
    object-fit: contain
    margin-right: 16px
.modalContainer
  @extend .welcomeScreenContainer
  background-color: rgba(0,0,0,0)
  margin: 0 auto
  padding: 0
  width: max-content !important
  & :global
    .ant-modal-confirm-btns, .anticon-info-circle
      display: none
    .ant-modal-body
      padding: 0
      border-radius: 4px
    .ant-modal-confirm-body-wrapper
      &::after, &::before
        content: none
    .ant-modal-confirm-content
      min-width: 900px
      margin: 0
      > div
        margin: 0 auto !important
        