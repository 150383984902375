@import '../../theme/_theme.sass'

.container, .containerJ
	max-width: 600px
	width: 100%
	padding: 24px 30px
	margin-top: -16px
	border-radius: 4px
	background-color: $Neutral100
	h1
		@include font-face-semibold(16px, $Primary600)
		padding-bottom: 12px
		border-bottom: 1px solid $Neutral200
		width: 100%
	h2
		@include font-face-medium(16px)
		line-height: 24px
	p
		@include font-face-regular(14px, $Neutral700)
		line-height: 20px
	ol
		width: 75%
		padding: 0 0 0 16px
		margin: 0
	li::marker
		@include font-face-medium(16px)
	li > div
		padding-left: 8px

.containerJ
	max-width: 400px