@import '../../theme/_theme.sass'

.container
	font-family: SF Pro
	h3
		@include font-face-medium(16px, $Neutral800)
		line-height: 22px
		span
			@include font-face-semibold(16px, $Primary600)

.nfContainer
	margin-bottom: 0 !important
	@include font-face-semibold(16px, $Primary600)

.uniSelect
	margin: 12px
	width: 300px

	& :global
		.ant-select-selector
			border-radius: 4px !important
			border: solid 2px $Neutral300
			background-color: $Neutral100 !important
.downloadB
	padding: 0
	margin: 12px 0
	@include font-face-semibold(16px, $Primary600)
	height: 44px

.pdfContainer
	width: 400px
	height: 520px

.pdfviews
	font-family: SF Pro
	border-right: 1px solid $Neutral200
	button
		margin-bottom: 24px
	p
		margin-top: 12px
		@include font-face-regular(12px)

	&::after
		content: 'OR'
		display: block
		line-height: 30px
		position: absolute
		top: 50%
		right: -10px
		background: $White

.prefilledViews
	font-family: SF Pro
	button
		margin-bottom: 24px
	p
		margin-top: 12px
		@include font-face-regular(12px)

.formTitle
	font-size: 16px
	line-height: 1.5
	font-weight: bold
	color: $Neutral800
	margin: 16px 0 0 30px
	span
		font-size: 21px
		margin-right: 8px

.stepForm
	padding-left: 18px

.steps
	cursor: pointer
	padding: 36px 18px
	@include font-face-semibold(16px, $Neutral1000)
	& :global
		.ant-steps-item-wait .ant-steps-item-icon
			border-color: $Primary600
		.ant-steps-item-wait .ant-steps-icon
			color: $Primary600
		.ant-steps-item-icon
			width: 24px
			height: 24px
			font-size: 14px
			line-height: 24px
		.ant-steps-item-title
			line-height: 24px
			&::after
				top: 12px
				background-color: $Primary300 !important

.downloadFilledPDF
	img
		margin: 48px 0
		width: 120px
		object-fit: contain
	h1
		@include font-face-medium(18px,$Neutral1000)
	ol
		padding: 12px 0 15px 12px
		margin: 0
		@include font-face-regular(12px, $Neutral1000)
	button
		@include primaryButton(12px, 36px)
		margin: 16px 0 40px
		
	& :global
		.ant-alert-info
			padding: 12px
			background-color: $Neutral100
			border: none
			border-radius: 4px
		.anticon-info-circle
			margin-right: 8px
			font-size: 16px
			color: $Neutral1000
		.ant-alert-message
			@include font-face-medium(12px,	$Neutral1000)
		.ant-alert-description
			> span
				margin-top: 15px
				@include font-face-medium(12px,$Primary600)

.uniForm
	width: 100%
	& :global
		.ant-form
			justify-content: center