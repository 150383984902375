@import '../../theme/_theme.sass'

.container
  padding: 8px 12px
  width: 100%
  align-items: center
  border-radius: 4px
  &[aria-label="error"]
    background-color: $Red100
    .icon, .message, a
      color: $Red600
  &[aria-label="ready"]
    background-color: $Orange100
    .icon, .message, a
      color: $Orange400
  &[aria-label="processing"]
    background-color: $Yellow200
    .icon, .message, a
      color: $Yellow700
  &[aria-label="neutral"]
    background-color: $Neutral100
    .icon, .message, a
      color: $Neutral400
  &[aria-label="info"]
    background-color: $Primary100
    .icon, .message, a
      color: $Primary400
  &[aria-label="success"]
    background-color: $Teal100
    .icon, .message, a
      color: $Teal600
  .icon
    display: inline
    align-self: flex-start
    font-size: 20px
    line-height: 24px
  .message
    display: inline !important
    vertical-align: top
    @include font-face-medium(14px)
    margin-left: 8px
    a
      padding: 0 2px
      text-decoration: underline
  +lg
    max-width: 90%