@import '../../theme/_theme.sass'

.important::after
  content: '*'
  color: $Red400

.container
  width: 100%
  margin: 24px 0
  align-items: flex-start
  .form
    flex: 0.5
    flex-direction: column
    align-items: flex-start
    &[aria-disabled='true']
      opacity: 0.7
      cursor: not-allowed
    .header
      @include font-face-regular(14px)
      margin-bottom: 4px
    .title
      @include font-face-medium(14px)
      margin-top: 16px
      margin-bottom: 8px
    .submit
      margin-top: 32px
      margin-bottom: 16px
      line-height: 2
    .sopInput
      width: calc(100% - 80px)
      color: $Neutral1000
    .dateInput
      min-width: 220px
  img
    flex: 0.5
    margin-left: 24px
