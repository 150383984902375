@import '../../theme/_theme.sass'

.accordion
	font-family: SF Pro
	flex-direction: column
	.item
		scroll-margin: 60px
		background-color: white
		flex-direction: column
		margin-bottom: 24px
		border-radius: 7px
		transition: all 0.3s
		color: $Neutral1000
		border: 2px solid $Neutral200
		&[aria-expanded="true"]
			border: 2px solid $Primary600
		&:hover
			border: 2px solid $Primary600
			&[aria-expanded="false"]
				box-shadow: 0 0 6px $Primary300
				& .title, .expandIndicator
					color: $Primary600
		&[aria-label="locked"]
			opacity: 0.5
		&[aria-label="complete"]
			border: 2px solid $Teal600
			&[aria-expanded="false"]
				border-color: $Teal400
			&:hover
				&[aria-expanded="false"]
					box-shadow: 0 0 6px $Teal500
		&[aria-label="processing"]
			border: 2px solid $Yellow400
			&:hover
				&[aria-expanded="false"]
					box-shadow: 0 0 6px $Yellow400
		&[aria-label="readyForReview"]
			border: 2px solid $Orange400
			&:hover
				&[aria-expanded="false"]
					box-shadow: 0 0 6px $Orange400
		.header
			flex-wrap: wrap
			align-items: center
			cursor: pointer
			padding: 28px 32px
			.iconContainer
				margin-right: 16px
				align-items: flex-end
				max-width: 37.5px
				.icon, >svg
					color: $Primary600
					width: 37.5px
					font-size: 37.5px
				.check
					color: $Teal600
					width: 35px
					font-size: 35px
			.title
				font-family: SF Pro
				@include font-face-medium(16px)
			.statusText
				@include font-face-semibold(16px)
				margin-left: auto
				align-items: center
				&[aria-label="incomplete"],&[aria-label="locked"],&[aria-label="pending"]
					color: $Neutral1000
				&[aria-label="complete"]
					color: $Teal600
				&[aria-label="processing"]
					color: $Yellow600
				&[aria-label="readyForReview"]
					color: $Orange600
			.expandIndicator
				font-size: 21px
				margin-left: 16px
				transition: 0.3s transform
				&[aria-label="incomplete"],&[aria-label="locked"],&[aria-label="pending"]
					color: $Neutral1000
					&[aria-expanded="true"]
						color: $Primary600
				&[aria-label="complete"]
					color: $Teal500
				&[aria-label="processing"]
					color: $Yellow600
				&[aria-label="readyForReview"]
					color: $Orange600
				&[aria-expanded="true"]
					transform: rotate(90deg)
			+lg
				padding: 16px
				&[aria-expanded="true"]
					border-bottom: 1px solid $Neutral200
				.statusText
					@include font-face-bold(12px)
				.expandIndicator
					font-size: 12px
				.iconContainer
					max-width: 24px
					margin-right: 10px
					.icon, >svg, .check
						padding: 1.5px 0
						width: 18px
						font-size: 20px
		.expandedContent
			overflow: visible
			transition: 0.3s
			transform-origin: top
			flex-direction: column
			margin-left: 88px
			width: calc(100% - 176px)
			&[aria-expanded="false"]
				height: 0px
				opacity: 0
				transform: scaleY(0)
			&[aria-expanded="true"]
				margin-top: 12px
				opacity: 1
				height: auto
				transform: scaleY(1)
				padding-bottom: 28px
			& :global
				.ant-btn-primary
					font-weight: 600
			+lg
				width: 100%
				max-height: 80vh
				overflow: auto !important
				margin: 0
				>div
					padding: 16px
				&[aria-expanded="true"]
					margin-top: 0

.footer
	padding: 0
	border-top: 1px solid $Neutral200

.displayNone
	display: none

=statusStyle($color: $Teal600)
	& :global
		.ant-modal-content
			-webkit-box-shadow: 0px 3px 20px 0px $color
			-moz-box-shadow: 0px 3px 20px 0px $color
			box-shadow: 0px 3px 20px 0px $color
		.ant-modal-header
			h1,svg
				color: $color !important
			button
				svg
					color: white !important

.complete
	+statusStyle($Teal600)

.processing
	+statusStyle($Yellow400)
	

.formModal
	font-family: SF Pro
	width: 70%
	& :global
		.ant-modal-body
			padding: 0 24px 24px
		.ant-alert-icon
			align-self: baseline
		.ant-modal-content
			overflow: hidden
			border-radius: 6px
		.ant-modal-close-icon
			padding: 40px 40px 0 0
			color: $Neutral1000
		.ant-modal-header
			font-family: SF Pro
			border-radius: 6px 6px 0 0
			border-bottom: 2px solid $Neutral200
			padding: 32px
			>div
				display: inline-flex
				width: 100%
				align-items: center
			>div>span
				height: 35px
				color: $Primary600
				font-size: 35px
			button
				margin: 0 60px 0 auto
			button + button
				margin-left: -48px
			h1
				padding-left: 8px
				@include font-face-semibold(20px, $Neutral800)
				margin: 0
		+lg
			.ant-modal-header
				padding: 16px
				>div>span
					height: 22px
					color: $Primary600
					font-size: 22px
			.ant-modal-close-icon
				padding: 0
.label
	@include font-face-semibold(16px)
	line-height: 22px
	margin-left: 12px
	padding: 4px 8px
	border-radius: 20px
	&[aria-label="incomplete"],&[aria-label="locked"],&[aria-label="pending"]
		color: $Neutral1000
	&[aria-label="complete"]
		color: $Teal600
	&[aria-label="processing"]
		color: $Yellow700
		background-color: $Yellow100
	&[aria-label="readyForReview"]
		color: $Orange600

.mButtonContainer
	flex-basis: 100%