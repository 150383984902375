@import '../../theme/_theme.sass'

.guideOpener
	cursor: pointer
	width: 100%
	height: 100%
	margin-left: 48px
	border-radius: 12px 0 0 12px
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 10px 20px 0 rgba(0, 0, 0, 0.15)
	background-image: linear-gradient(165deg, $Primary700 47%, $Primary600 99%)
	h1
		width: 90px
		margin-top: 110px
		text-align: right
		line-height: 1.33
		@include font-face-semibold(18px,	$White)

	button
		display: block
		margin: 24px auto
		width: 69px
		height: 69px
		span
			font-size: 24px !important

.helpContent
	padding-left: 46px
	width: 100%
	background-color: white
	
.closeButton
	border-radius: 4px
	@include font-face-medium(12px, white)
	line-height: 20px
	padding: 0 8px
	height: 28px
	span:last-child
		margin-left: 6px	

.selectSection
	width: 400px
	margin-top: 12px
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1)
	& :global
		.ant-select-arrow
			font-size: 14px
			color: $Neutral600
		.ant-select-selector
			border: none !important
		.ant-select-selection-item
			@include font-face-medium(14px, $Neutral800)

.infoPanel
	width: 400px
	margin-bottom: 8px
	border: solid 1px $Neutral200
	border-radius: 4px !important
	background: white
	& :global
		.ant-collapse-header
			border-radius: 4px !important
			background: $White
			@include font-face-medium(14px, $Neutral1000)
		.ant-collapse-content
			border-radius: 0 0 4px 4px !important

.infoCollapse
	border: none
	margin-top: 32px
	background-color: $Neutral100

.infoImg
	width: 100%
	padding: 14px
	max-height: 80vh

.imgModal
	border-radius: 6px
	min-width: 900px
	button:last-child
		display: none

.infoMsg
	padding: 12px
	@include font-face-semibold(14px)

.singleInfo
	margin-top: 32px
	border: 1px solid $Neutral200
	max-width: 398px

.helpButtons
	margin: 24px 12px 0 0
	>span
		display: block
		@include font-face-medium(14px, $Primary600 )
		line-height: 22px
	button
		margin-top: 8px
		@include primaryButton(14px, 30px)
		@include font-face-medium(14px, white)
		padding: 0 12px
		span > span
			margin-right: 6px

.accordionContainer
	background-color: $Neutral100
	max-width: 432px
	margin-top: 32px
	padding: 16px
	border-radius: 4px
	>h1
		margin: 0
		@include font-face-semibold(16px, $Neutral900)
		>span
			font-size: 17px
		