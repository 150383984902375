
	@import '../../theme/_theme.sass'

.formAlert
	margin-top: 48px
	margin-left: 12px
	max-width: 400px
	border: none
	border-radius: 4px
	p
		font-size: 14px
		color: $Neutral800
		padding: 22px 40px 0 0
		margin: 0

	img
		display: none
	& :global
		.ant-alert-icon
			font-size: 25px
			color: $Primary400
			margin-right: 8px
		.ant-alert-message
			@include font-face-medium(14px, $Primary400)

.cardWelcome
	margin-top: 14px
	margin-left: 64px
	min-height: 530px
	color: $Neutral800
	img
		height: 240px
		margin-bottom: 45px
	h1
		font-size: 24px
		font-weight: bold
		line-height: 0.75
	p
		font-size: 16px
		font-weight: 300
		line-height: 1.1
		margin-bottom: 22px
	button
		@include primaryButton

.cardDetails
	width: 386px
	> div:nth-child(1)
		> div
			margin-top: 0
	> div:nth-child(3)
		background: $White
		margin-top: 28px
		border: 2px solid $Neutral200
		border-radius: 8px
		padding: 24px

.iFeeAlert
	margin: 14px 0 -28px
	svg
		width: 20px
		height: 20px
	@include alertRestyle(14px, $Red500)
	background-color: $Red100
	font-weight: 500

.sFeeAlert
	width: 100%
	margin: 14px 0 -28px
	background-color: $Teal100
	svg
		width: 20px
		height: 20px
	@include alertRestyle(14px, $Teal600)
	font-weight: 500

.topupForm
	width: 100%
	padding-bottom: 0 !important
	

.step1
	margin: 14px 12px 24px 24px
	flex-flow: wrap
	h1,h2
		width: 100%
		display: block
	h1
		@include font-face-semibold(24px, $Neutral800)
		line-height: 1
		margin: 0 0 12px
	h2
		@include font-face-regular(16px, $Neutral800)
	>button
		@include primaryButton(14px, 42px)
		margin: 24px 0
	>div
		display: block
		width: 100%

.step2
	@extend .step1
	h1
		color: $Neutral1000
		a
			text-decoration: underline
			cursor: pointer
	img
		width: 600px
		object-fit: contain
		margin: 24px 0

.step3
	@extend .step2
	button
		margin-top: 32px

.paysevisContainer
	margin: 0 16px 24px

.dsTabs
	min-height: 400px
	
.tabContent
	h1
		@include font-face-semibold(24px, $Neutral800)
		line-height: 1
		margin: 0 0 48px
	button
		margin: 12px 0