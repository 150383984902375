@import '../../theme/_theme.sass'

.vstContainer
  width: 100%
  font-family: SF Pro

.topMsg
  font-size: 14px
  font-weight: 500
  color: $Neutral800
  margin-bottom: 20px
  span
    color: $Primary600
