@import '../../theme/_theme.sass'

.container
	width: 100%
	*
		font-family: SF Pro
	> button
		margin-top: 48px
		border-radius: 4px

.calendar
	width: 80%
	font-family: SF Pro
	margin-top: 48px
	border-radius: 14px
	border: solid 2px $Neutral400
	table
		padding: 32px
	td
		width: 42px
		&::before
			width: 42px
	thead
		color: $Neutral800
		font-size: 12px
		font-weight: 500
		line-height: 1
		letter-spacing: 0.36px
	& :global
		.ant-picker-panel
			@include noselect
			padding: 10px !important
			border-radius: 14px
		.ant-picker-cell-inner
			background: transparent
		.ant-picker-cell-in-view
			pointer-events: auto
			position: relative
			font-size: 18px
			font-weight: normal
			line-height: 1.22
			letter-spacing: normal
			color: $Neutral1000
		.ant-picker-panel
			border-top: none

		.ant-picker-cell-selected
			font-weight: bold
			color: $Primary600
			> div.ant-picker-cell-inner
				height: 36px
				width: 36px
				background: transparent
				color: $Primary600
				&::before
					border: none
			&::before
				border-radius: 50px !important
				border: 2px solid $Primary600
				width: 36px
				height: 36px
				transition: all 0.1s
				transform: translate(20%, -50%)

.calHeader
	padding: 12px
	width: 100%
	font-size: 20px
	font-weight: 600
	font-stretch: normal
	font-style: normal
	line-height: normal
	letter-spacing: normal
	color: $Neutral800
	&::after
		content: ""
		background: $Neutral200
		position: absolute
		top: 105px
		left: 6%
		width: 65%
		height: 1px

.slots
	width: 100%
	margin-top: 24px
	button
		font-size: 12px
		font-weight: 500
		color: $Neutral800
	*
		font-family: SF Pro
	& :global
		.ant-btn:focus
			font-weight: bold
			border-width: 2px

.finalInfo
	width: 100%
	margin-top: 80px
	p
		width: 100%
		font-size: 14px
		line-height: 1.43
		color: $Neutral900

	div:nth-child(2)
		> span
			font-size: 28px
			font-weight: 500
			color: $Primary600
	span
		font-size: 16px
		font-weight: 500
		text-align: left

.confirmContainer
	width: 100%
	font-family: SF Pro
	img
		margin: 50px 0 24px 0
		height: 120px
		width: auto

.successText
	width: 100%
	font-size: 28px
	font-weight: 400
	color: $Teal700
	span
		margin-right: 10px

.appDetails
	width: 100%
	p
		color: $Black
		margin: 5px
		font-size: 18px
		width: 100%
		text-align: center
		>span
			font-weight: 600
			color: $Primary600
	> span
		font-size: 18px
		font-weight: 500
		color: $Neutral800
		margin-bottom: 36px

.noSlots
	flex-flow: column
	margin-top: 48px
	width: 70%
	>svg
		color: $Red600
		margin-bottom: 16px
		width: 40px
		font-size: 40px
	h1,h2
		margin-bottom: 12px
	h1
		@include font-face-semibold(20px, $Red600)
	h2
		@include font-face-regular(16px, $Neutral800)
		line-height: 24px
	button
		margin-top: 12px
		width: 170px
		@include primaryButton(16px, 40px)
		
		
		