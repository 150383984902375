@import '../../theme/_theme.sass'
 	
.welcomeScreenContainer
	overflow: auto
	display: block !important
	height: 87vh
	max-width: 1344px
	width: 100%
	margin: 0 auto
	margin-top: 24px
	background-color: white
	font-family: SF Pro
	line-height: 1

.header
	display: inline
	h2
		display: inline
		@include font-face-bold(36px)
		line-height: 42px
		margin: 0
		vertical-align: middle
	img
		height: 42px
		width: 42px
		object-fit: contain
		margin-right: 16px

.welcomeBanner
	margin: 96px 160px 52px
	button
		display: block
		margin-left: auto
		@include primaryButton(16px, 48px)
		svg
			margin-left: 2px
			font-size: 14px
		width: 256px

.iconsContainer
	width: 896px
	> div
		width: 256px
		height: 146px
		flex-direction: column
		align-items: center
		border-radius: 16px
		border: solid 1px $Neutral300
		span
			font-size: 36px
			font-weight: bold
			position: relative
			top: -20px
			color: $Primary600
			background-color: $White
			width: 50px
			text-align: center
		p
			@include font-face-medium(14px,$Neutral800)
			margin: 0 36px auto
			line-height: 22px
			text-align: center
		&:last-child
			p
				margin: 0 16px auto
	svg
		height: 64px
		color: $Primary500

.ratesContainer
	width: 100%
	margin-bottom: 24px
	>
		width: 100%
	button
		margin-left: auto
		margin-bottom: 8px
		border: 2px solid $Primary600
		@include font-face-semibold(14px, $Primary600)
		height: 36px !important
		span:last-child
			font-size: 17px
			height: 17px
			vertical-align: sub
			margin-left: 4px
		&:hover, &:active, &:focus
			background-color: $Primary600
			color: white

.leftUL
	list-style: none
	width: 336px
	z-index: 2
	height: 100%
	flex-grow: 0
	margin: 0
	padding: 0
	background-color: $Neutral100
	@include box-shadow-20-40()
	li:nth-child(n+2) > div
		height: 64px
		font-size: 16px
		align-items: center
		> div
			display: flex
			text-align: center
		img
			margin: auto
			width: 48px
			object-fit: contain
		p
			text-align: center
			width: 100%
			color: $Neutral1000
			font-weight: bold
			font-size: 14px
			margin: 0

	li:nth-child(1)
		height: 56px
		> div
			height: 56px
		p
			margin: 0
			font-size: 10px
			color: $Neutral800
			font-weight: bold
			text-transform: uppercase
			text-align: center

	li:nth-child(n + 1)
		&::after
			content: ''
			display: block
			position: relative
			bottom: 0
			width: 88%
			left: 12%
			overflow: hidden
			border-top: 1px solid $Neutral200
	li:last-child
		&::after
			content: none

.rightUL
	list-style: none
	width: 222px
	z-index: 1
	height: 92%
	flex-grow: 0
	margin: auto 0
	padding: 0
	background-color: $Neutral100
	@include box-shadow-20-40()
	li:nth-child(n+2) > div
		height: 64px
		font-size: 16px
		align-items: center
		> div
			display: flex
			text-align: center
		img
			margin: auto
			width: 48px
			object-fit: contain
		p
			text-align: center
			width: 100%
			color: $Neutral1000
			font-weight: bold
			font-size: 12px
			margin: 0

	li:nth-child(1)
		height: 50px
		> div
			height: 50px
		p
			margin: 0
			font-size: 8px
			color: $Neutral800
			font-weight: bold
			text-transform: uppercase
			text-align: center
	li
		&::after
			content: ''
			display: block
			position: relative
			bottom: 0
			width: 88%
			overflow: hidden
			border-top: 1px solid $Neutral200
	li:last-child
		height: 58px
		&::after
			content: none

.welcomeScreen
	height: calc(100vh - 73px)
	width: 100%
	background-color: $Neutral100

.redDot
	height: 7px
	width: 7px
	margin: 0 0 2px 4px
	background-color: $Orange700
	border-radius: 50%
	display: inline-block

.logosContainer
	width: 100%
	margin-bottom: 56px
	>div
		width: 1024px !important
	img
		width: 10%
		padding: 0 14px
		object-fit: contain

.listContainer
	width: 1024px
	margin-top: 56px
	> div > h2
		@include font-face-semibold(24px, $Neutral1000)
		line-height: 36px
		text-align: left
		margin-bottom: 24px

.liReasons
	list-style: none
	padding: 0
	margin: 0
	li
		@include font-face-medium(14px, $Neutral800)
		position: relative
		line-height: 22px
		margin: 0 12px 24px 40px
		span
			position: absolute
			left: -40px
			color: $Teal600
		svg
			padding: 1px
			margin-top: 3px
			width: 24px
			height: 20px