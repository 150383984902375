@import '../../theme/_theme.sass'

.selectContainer
	width: 100%
	margin: 32px 0 64px 0

.typeCard
	border-radius: 16px
	width: 256px
	height: 256px
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1)
	img, span
		display: block
		margin: auto
	img
		margin: 8px auto 16px auto
		width: 100px
	span
		text-align: center
		@include font-face-medium(16px, $Neutral800 )

	&:hover
		border: 2px solid $Primary500
		span
			color: $Neutral1000

.accepted
	font-family: SF Pro
	margin-bottom: 30px
	h1,h2
		margin-bottom: 24px
		line-height: 24px
	h1
		line-height: 32px
		@include font-face-medium(28px,	$Teal600)
	h2
		@include font-face-medium(16px,	$Neutral800)
	img
		width: 271px
		object-fit: contain
		display: block
		margin: auto

	button
		border-radius: 4px !important
		@include primaryButton(16px, 46px)

.processing
	width: 100%
	margin: 8px 0 44px 0
	h1
		@include font-face-regular(22px, $Neutral800)
		margin-bottom: 44px
		line-height: 24px

.processingTypes
	font-family: SF Pro
	.typeCard
		width: 176px
		height: 150px
		padding: 14px 16px
		svg
			font-size: 32px
		img
			margin: 0 auto
			width: 144px
			border-radius: 4px 4px 0 0
		h1
			display: block !important
			text-align: center
			margin: 0
			@include font-face-semibold(48px, $Black)
			line-height: 86px !important

		span
			margin-top: 8px
		& :global
			.ant-card-body
				padding: 0

.infoImg
	margin: 24px auto 0 auto
	width: 720px
	object-fit: contain
	max-height: 80vh

.imgModal
	border-radius: 6px
	min-width: 900px
	button:last-child
		display: none
	& :global
		.ant-modal-close
			top: 10px
			right: 10px

.attorney
	font-family: SF Pro
	line-height: 22px
	h1
		margin-bottom: 41px
		@include font-face-bold(16px, $Neutral800)
	img
		width: 98px
		border-radius: 4px
		object-fit: contain
	h2
		margin-bottom: 8px
		@include font-face-bold(20px, $Neutral1000)
	h3
		margin-bottom: 24px
		@include font-face-medium(16px, $Neutral600)
	&:last-child
		margin-left: 46px
	&:first-child
		border-right: 1px solid $Neutral200
		&::after
			content: 'OR'
			display: block
			line-height: 30px
			position: absolute
			top: 45%
			right: -10px
			background: $White

.rejected
	width: 100%
	margin-bottom: 36px
	button:first-child
		margin-right: 12px
		@include primaryButton(14px, 38px)
	a
		@include ghostButton(14px, 38px)
		font-weight: 500
		line-height: 1.4
		padding: 8px 16px !important

.attorneyDetails
	margin-bottom: 32px
	>div
		line-height: 22px !important
		margin-bottom: 8px
		@include font-face-semibold(16px, $Neutral800)
	>div>span
		margin-right: 4px
		@include font-face-medium(16px, $Primary600 )
		
.explainerContainer
	font-family: SF Pro

.explainer
	margin-top: 48px
	h2
		@include font-face-semibold(16px,	$Neutral800)
	textarea
		border-radius: 4px
		height: 105px
		margin-bottom: 66px
	button
		@include primaryButton(16px, 48px)
		margin: 48px 0 60px 0

.slotInfo
	>div
		display: block !important
		line-height: 20px
		margin-bottom: 12px
		text-align: center
		vertical-align: middle
		@include font-face-regular(14px, $Neutral900)
		>span:last-child
			margin-left: 4px
			@include font-face-semibold(14px, $Primary600)
			vertical-align: baseline
		>span
			height: 25px
			vertical-align: middle
			margin-right: 4px
			color: $Primary600
			font-size: 21px

.bookedInfo
	font-family: SF Pro
	margin-bottom: 40px
	line-height: 22px
	>div>div
		display: block
	img
		margin: 0 42px 0 auto
		width: 176px
	h2, h3
		margin: 0
	h2
		@include font-face-bold(16px, $Neutral1000)
	h3
		margin-bottom: 32px
		@include font-face-medium(16px, $Neutral600)
	p
		margin-bottom: 4px
		@include font-face-regular(16px, $Neutral900)
	a
		@include font-face-medium(16px, $Primary600)

	h1
		align-items: center
		margin-bottom: 48px
		@include font-face-regular(28px,	$Teal700)
		span
			vertical-align: middle
			height: 35px
			font-size: 32px
			margin-right: 8px
