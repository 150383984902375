@import "./CommonModals.module"

.modal
	width: 80vw !important
	max-width: 1400px
	& :global
		.ant-modal-header
			height: 98px
		.ant-modal-title
			width: 100%
	+md
		width: 96vw !important

.title
	display: inline-flex
	align-items: center
	width: 100%
	@include font-face-semibold(20px, $Neutral800)
	line-height: 24px
	> span > svg
		font-size: 30px
		margin-right: 8px
	+md
		font-size: 16px
		flex-wrap: wrap
		line-height: 30px
		> span > svg
			font-size: 24px

.chatButton
	height: 40px
	font-size: 16px
	svg
		margin: 0
	+md
		display: none
		margin-top: 12px

.extrasB2
	@include primaryButton(16px, 40px)
	padding: 0
	width: 166px
	svg
		color: white
		font-size: 14px
	+md
		@include primaryButton(14px, 32px)

.extras
	display: inline-flex
	align-items: center
	margin-left: auto
	margin-right: 48px
	gap: 24px
	h2
		@include font-face-semibold(16px, $Neutral800)
		margin: 0 0 0 auto
	+md
		display: block
		width: 100%
		margin: 4px 0 0
		h2
			display: none

.planSideBar
	margin: auto
	width: max-content
	max-width: 180px
	padding: 7px
	span
		display: block
		cursor: pointer

.planContent
	max-height: 60vh
	overflow-y: auto
	padding-right: 12px

.sideTitle
	@include font-face-regular(16px)
	line-height: 24px
	margin-bottom: 12px
	padding-left: 12px
	&[aria-label="active"]
		font-weight: 600
		border-left: 2px solid $Primary600

.planItemContent, .planHeader
	max-width: 748px

.planHeader
	border-bottom: 2px solid $Neutral200
	margin: 8px 0 22px
	p
		margin: 0
	h1, .rightData
		@include font-face-semibold(32px)
		line-height: 48px
		align-items: baseline
	+md
		h1
			font-size: 24px
			line-height: 28px
		.rightData
			display: none
		

.planItemContentItem
	margin-bottom: 12px

.planItem
	h1
		@include font-face-semibold(24px)
		line-height: 32px
		margin: 0 0 24px
	+md
		h1
			font-size: 20px
			line-height: 28px
			margin: 0 0 12px

.rightData
	display: flex
	justify-content: flex-end
	align-items: center
	@include font-face-medium(20px)
	line-height: 24px
	p
		margin: 0
	+md
		font-size: 16px
		align-items: baseline

.leftData
	h2
		@include font-face-bold(16px)
		line-height: 24px
		margin: 0
	p
		@include font-face-regular(16px)
		line-height: 24px
		margin: 0 0 8px