@import '../../theme/_theme.sass'

.markCompleteButton
  font-family: SF Pro
  z-index: 10
  @include primaryButton(14px , 40px, $Teal700)
  @include font-face-bold(14px, $White)
  line-height: 20px
  width: 250px
  padding: 0
  span
    vertical-align: middle
  svg
    padding: 0 2px
    font-size: 20px
  & :global
    .anticon
      height: 20px
      vertical-align: middle
      +lg
        height: 16px !important
  &:hover
    background-color: $Teal600
    border-color: $Teal600
  +lg
    width: auto
    padding: 0 8px
    @include primaryButton(12px , 32px, $Teal700)
    svg
      padding: 0 2px
      font-size: 16px

.cohere
  font-family: SF Pro
  z-index: 10
  line-height: 20px
  padding: 0 12px
  height: 40px
  border: 2px solid $Red700
  @include font-face-bold(14px, $Red700)
  border-radius: 4px
  span
    vertical-align: middle
  svg
    padding: 0
    font-size: 20px
  & :global
    .anticon
      height: 20px
      vertical-align: middle
  &:hover
    background-color: $Red700
    border-color: $Red700
    color: $White