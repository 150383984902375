@import '../../theme/_theme.sass'

.welcomeScreenContainer
	overflow: auto
	display: block !important
	height: 87vh
	max-width: 1344px
	width: 100%
	margin: 0 auto
	margin-top: 24px
	background-color: white
	font-family: SF Pro
	line-height: 1

.welcomeBanner
	width: 1069px
	margin: 8vh auto 64px auto
	button
		font-family: SF Pro
		@include primaryButton(16px, 48px)
		height: 48px
		width: 232px

	button:nth-child(2)
		svg
			transform: rotate(90deg)
	h2
		color: $Neutral1000
		font-family: SF Pro
		font-weight: bold
		font-size: 36px
		line-height: 48px
		text-align: left
		margin: 0

.welcomeScreen
	height: calc(100vh - 73px)
	width: 100%
	background-color: $Neutral100

.contentList
	width: 1152px

.heroIcon
	height: 36px
	img
		height: 36px
		width: auto
		object-fit: contain
.content
	align-self: flex-end
	padding: 0 8px 6px 30px
	margin-top: -16px
	font-family: SF Pro
	&::after
		padding: 0
		content: ""
		width: 45%
		height: 2px
		background-color: $Primary100
		position: absolute
		left: 5px
		bottom: 2px

	h1
		font-size: 20px
		font-weight: 600
		font-stretch: normal
		font-style: normal
		line-height: 22px
		color: $Neutral1000
		margin: 16px 0 6px 0
	p
		font-size: 14px
		font-weight: 500
		font-stretch: normal
		font-style: normal
		text-align: left
		line-height: 1.57
		padding-right: 20px
		color: $Neutral800
		margin: 0
	button
		padding: 0
		margin-bottom: 3px
		font-weight: 600
		font-size: 14px
		color: $Primary600
		span
			margin: 0 !important
			svg
				padding: 1.8px 0

.index
	color: $Primary100
	font-size: 250px
	font-weight: bold
	line-height: 0.69
	span
		width: fit-content

.header
	display: inline
	h2
		display: inline
		@include font-face-bold(36px)
		line-height: 42px
		margin: 0
		vertical-align: middle
	img
		height: 42px
		width: 42px
		object-fit: contain
		margin-right: 16px