@import '../../theme/_theme.sass'

.container
	background-color: $Neutral100
	height: 100%
	overflow: auto
	width: 100%
	padding: 0 48px 24px
	flex-direction: column
	.childContainer
		width: 100%
		max-width: 1088px
		margin: 16px auto 32px
		flex-direction: column
	+lg
		padding: 0 12px
		overflow: visible
.requestButton
	background-color: $Primary
	color: $White
	font-weight: 600
	margin-top: 30px
	height: 46px
	border-radius: 6px
	width: 25vw

.requestButton:hover
	background-color: $White
	color: $Primary
	border: 3px solid inner $Primary