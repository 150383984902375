@import '../../theme/_theme.sass'
  
.header
  margin-top: 5vh
  align-items: flex-end
  .progressContainer
    flex-direction: column
    margin-bottom: 24px
    flex: 0.5
    img
      margin-bottom: 24px
      height: 60px
      width: min-content
      object-fit: contain
    +lg
      flex-direction: row
      width: 100%
      flex: 1
      align-items: center
      justify-content: center
      margin: 32px 0
      img
        height: 30px
        margin: 0
        margin-right: 12px
  .greetingContainer
    flex: 0.5
    align-items: flex-end
    margin-left: 24px
    margin-right: 16px
  +lg
    flex-direction: column
    margin-top: 20px