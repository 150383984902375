@import '../../theme/_theme.sass'

.messageBox, .messageBoxAI
  padding: 16px
  border: 2px solid #BCA1CF
  background-color: white
  border-radius: 3px
  flex-direction: column
  position: relative
  max-width: 420px
  left: 48px
  bottom: 32px
  margin-left: -16px
  .title
    @include font-face-regular(11px)
  .message
    @include font-face-medium(14px)
    margin-top: 8px

.messageBox
  border: 2px solid $Yellow400

.mobileHelp
  width: 100%
  align-items: center
  justify-content: center
  margin-bottom: 32px
  @include font-face-medium(14px, $Neutral800)
  button
    margin-left: 12px
    @include primaryButton(12px, 26px)
    font-weight: 500
    padding: 0 8px


.chatButton, .chatButtonAI
  margin-top: 16px
  align-self: flex-end
  border-color: white
  padding: 0 12px
  border-radius: 4px
  color: white
  height: 28px

.chatButtonAI
  height: 42px
  background-color: #BCA1CF
  &:hover
    background-color: #a065cb
    border-color: white