@import '../../theme/_theme.sass'

.container
	width: 100%
	border: none
	& :global
		.ant-card-body
			padding: 0

.successButton
	background: $Teal600
	border-color: $Teal600
	&:hover
		background: $Teal700
		border-color: $Teal700
