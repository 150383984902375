@import '../../theme/_theme.sass'

@import './InsuranceDocs.module'

.guideContainer
	h1
		display: flex
		max-width: fit-content
		margin: auto
		margin-bottom: 48px
		border: 1px solid $Neutral300
		border-radius: 12px
		@include font-face-semibold(16px, black )
		padding: 12px 16px
		> span
			border-radius: 100%
			@include font-face-regular(16px, $White )
			line-height: 24px
			background-color: $Primary500
			height: min-content
			padding: 1px 8px
			margin-right: 8px
	+lg
		h1
			@include font-face-semibold(14px, black )
			padding: 9px 12px
			> span
				@include font-face-regular(14px, $White )
				background-color: $Primary500
				padding: 0 8px
				margin-right: 8px

.awaiting, .automation
	width: 100%
	font-family: SF Pro
	line-height: 1
	h1
		margin-bottom: 24px
		@include font-face-medium(28px, $Neutral800 )
	h2
		font-size: 16px
		font-weight: normal
		line-height: 24px
		margin: 0
	img
		width: 28rem

.guideButton
	padding: 0
	border-radius: 24px
	height: 160px
	width: 100%
	position: relative
	span
		cursor: pointer
		height: fit-content
		position: absolute
		top: 0
		bottom: 32px
		left: 0
		right: 0
		margin: auto
		color: white
		width: 30%
		@include font-face-semibold(16px, white)
		>svg
			font-size: 20px
			width: 100%
			margin-bottom: 12px
	+lg
		margin-bottom: 48px
		border-radius: 0
	>svg
		cursor: pointer
		margin: 0 auto
		height: 160px
		color: $Primary600

.guide
	text-align: center
	+lg
		border: none
		&::after
			content: ""
			background: $Neutral200
			position: absolute
			bottom: 0
			left: 0
			right: 0
			height: 1px
			width: 80%
			margin: auto
		margin-bottom: 48px
		
.automation
	padding: 48px
	h2
		@include font-face-semibold(18px, $Neutral600)
		margin: 0
		text-align: center
	> svg
		color: $Teal600
		font-size: 68px
		display: block
		width: 100%
		margin: 10px auto

.guideContainer
	h3
		@include font-face-semibold(18px, $Primary600)
		margin: 0
		text-align: center