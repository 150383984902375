@import '../../theme/_theme.sass'

.container
  flex-direction: column
  width: 100%
  .info
    margin-bottom: 24px
    .benefits
      flex: 1
      flex-direction: column
      margin-top: 24px
      margin-left: 8px
      .benefitContainer
        flex-direction: column
        .benefit
          flex-direction: row
          align-items: center
          .index
            @include font-face-regular(16px, $Neutral800)
            padding: 4px 8px
            border: 1px solid $Neutral800
            border-radius: 20px
            height: 32px
            width: 32px
            justify-content: center
          .topic
            @include font-face-medium(14px, black)
            margin-left: 8px
        &:not(:last-child)
          .connector
            width: 1px
            height: 16px
            background-color: $Neutral300
            margin-top: 8px
            margin-bottom: 8px
            margin-left: 15px
    img
      flex: 1
    iframe
      margin-top: 24px
      flex: 1
      border: 1px solid rgba(0,0,0,0.1)
      border-radius: 4px
      height: 360px
  .webinarRegistrations
    flex-direction: column
    align-items: flex-start
    .webinarHeading
      margin-top: 16px
      display: flex
      align-items: center
      .hand
        @include font-face-regular(24px)
        line-height: 32px
        margin-bottom: 0.3em
      .selectWebinar
        @include font-face-medium(14px, black)
        line-height: 32px
        margin-left: 8px
      margin-bottom: 12px
  .registrationInfo
    display: block!important
    .timing
      @include font-face-medium(14px)
  .submitButton
    margin-top: 36px
    align-self: flex-start
    margin-bottom: 24px
    @include primaryButton(14px , 40px)
    
.date
  width: 116px
  display: flex
  flex-direction: column
  margin: 8px 12px
  span, p
    line-height: 22px
    margin: 0
  span
    @include font-face-semibold(14px)
    width: 100%
    display: block
  p
    @include font-face-regular(14px, $Neutral600)
    margin-top: 4px
    width: max-content
  
    
  