.video-card {
  .header-container {
    display: flex;
    flex-direction: row;

    .title-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      padding: 0 16px;

      .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 0;
      }

      .description {
        font-size: 14px;
        color: #627d98;
        font-weight: 500;
      }
    }
  }

  .ant-card-head-title {
    padding: 8px 0;
  }

  .ant-card-body {
    padding: 0;
  }

  .video-container {
    max-height: 540px;
  }
}

.article-card {
  .header-container {
    display: flex;
    flex-direction: row;

    .title-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      padding: 0 16px;

      .title {
        font-weight: 500;
        font-size: 18px;
      }
    }
  }

  .ant-card-head-title {
    padding: 8px 0;
  }

  .ant-card-body {
    padding: 0;
  }

  .description-container {
    max-height: 360px;
    display: flex;
    flex-direction: row;
    background: #f0f4f8;
    text-overflow: ellipsis;

    .image-container {
      flex: 1;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    span {
      display: flex;
      flex: 4;
      padding: 8px;
      height: 100%;
      text-overflow: ellipsis;
    }
  }

  .article-footer {
    display: flex;
    flex-direction: row-reverse;
    padding: 16px;
    align-items: center;

    button {
      margin: 0 8px;
    }
  }
}

.webinar-card {
  .header-container {
    display: flex;
    flex-direction: row;

    .title-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      padding: 0 16px;

      .title {
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 0;
      }

      .description {
        font-size: 14;
        color: #627d98;
        font-weight: 500;
      }
    }
  }

  .ant-card-head-title {
    padding: 8px 0;
  }

  .ant-card-body {
    padding: 0;
  }

  .webinar-container {
    background: #f0f4f8;
    height: 100%;
    img {
      object-fit: contain;
      width: 100%;
    }
  }

  .webinar-footer {
    display: flex;
    flex-direction: column;
    padding: 16px;

    .text-container {
      display: flex;
      flex: 1;
      flex-direction: column;

      .primary-text {
        font-size: 16px;
        font-weight: 600;
        color: #334e68;
      }

      .sub-text {
        font-size: 12px;
        font-weight: 300;
        color: #334e68;
      }
    }

    .available-slots {
      padding: 32px 0;
      .ant-radio-group {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
        grid-column-gap: 8px;
        grid-row-gap: 16px;
        .ant-radio-button-wrapper {
          text-align: center;
          border-radius: 20px;
        }
        .ant-radio-button-wrapper:not(:first-child):before {
          width: 0;
        }
      }
    }

    .actions-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      button {
        width: 100%;
      }
    }
  }
}

.video-carousel {
  max-width: 900px;
  margin: 0 auto;
}
