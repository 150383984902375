@import '../../theme/_theme.sass'

.contentContainer
	padding: 10px 0
	min-height: 300px
	height: 100%
	width: 100%
	margin-top: 24px
	transition: all .3s ease-in-out
	& :global
		.ant-spin-nested-loading, .ant-spin-container
			display: flex
			width: 100%
			min-height: 60vh
		

.stepsContainer
	padding: 0 24px
	
.steps
	+gradlySteps
	margin: 24px 0 0
	padding: 12px 52px
	& :global
		.anticon
			font-size: 32px
			line-height: 30px
		.ant-steps-icon
			vertical-align: inherit !important