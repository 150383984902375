@import 'InstructionalModal.module.sass'

.insModal
	width: 60% !important

.content
	max-height: 60vh
	overflow: auto
	img
		width: 70%
		height: 100%
		object-fit: contain