@import 'CommonModals.module.sass'

.insModal
	font-family: SF Pro
	width: 90% !important
	h2
		@include font-face-semibold(14px, $Neutral800)
		line-height: 20px
		width: 100%
		text-align: center
		margin-bottom: 16px
	&	:global
		.ant-row
			width: 100%
		.ant-modal-header
			display: inline-flex
			width: 100%
			height: 108px
			border-bottom: 2px solid $Neutral200
		.ant-modal-title
			margin: auto 0
		.ant-modal-close
			height: 108px
			width: 88px

.chatButton
	@include primaryButton(14px , 36px)
	color: white
	margin-right: 12px
	margin-bottom: 24px
	padding: 0 12px

.content
	display: flex
	width: 100%
	justify-content: center
	>div
		min-width: 60vw
	iframe
		width: 100%
		min-height: 65vh
	>div[aria-label="article"]
		width: 80vw
.title
	@include font-face-medium(20px, $Neutral800)
	b
		@include font-face-semibold(20px, $Neutral800)
	svg
		color: $Primary600
		margin-right: 4px
		font-size: 21px