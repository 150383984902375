.feed-detailed-popup {
  width: 90vw;
  top: 56px !important;

  .ant-modal-body {
    padding: 2px;

    .popup-iframe {
      border: 0;
      height: 80vh;
    }
  }

  .close-button {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.15);
    color: #a081d9;
  }

  .ant-modal-footer {
    background: #f0f4f8;

    .footer-container {
      display: flex;
      flex-direction: row;

      .got-questions-container {
        display: flex;
        flex-direction: column;
        flex: 1;

        .title {
          font-size: 18px;
          line-height: 20px;
          text-align: start;
          margin-bottom: 8px;
        }

        .message-us-action-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          button {
            margin-left: 16px;
          }
        }
      }

      .actions-container {
        display: flex;
        align-items: center;
      }
    }
  }
}
