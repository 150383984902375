@import '../../theme/_theme.sass'

.container
	>div >button
		margin-top: 12px
		border-radius: 4px
		font-weight: normal

.listCollapse
	margin: 32px 0
	width: 660px
	border-radius: 4px
	border-color: $Neutral200 !important
	>div
		border-color: $Neutral200 !important
	label
		display: flex
		font-size: 14px
		>span
			height: 100%
	& :global
		.ant-collapse-header
			font-family: SF Pro
			align-items: center !important
			height: 48px
			line-height: 1 !important
			background-color: $Neutral100
			@include font-face-semibold(14px,	$Neutral1000)
		.ant-collapse-item:first-child
			border-radius: 4px 4px 0 0
			overflow: hidden
		.ant-collapse-item:last-child
			border-radius: 0 0 4px 4px
			overflow: hidden

.completed
	border: 1px solid $Teal600 !important
	*
		border-color: $Teal600 !important

	& :global
		.ant-collapse-header
			background-color: $Teal100
			&:after
				content: "✔ All checked"
				position: absolute
				right: 30px
				border: 1px solid $Teal600
				border-radius: 20px
				padding: 0 10px
				line-height: 22px
				@include font-face-medium(14px,	$Teal600)

.completed + .completed
	border-top: none !important

.markasdone
	font-family: SF Pro
	background: $Green
	border-color: $Green
	border-radius: 4px
	height: 46px
	margin-left: auto
	@include font-face-medium(16px,$White)
