@import '../../theme/_theme.sass'
.container
	font-family: SF Pro
	width: 100%
	h1
		text-align: center
		width: 100%
		margin-bottom: 64px
		@include font-face-semibold(24px, $Neutral1000)
		line-height: 24px
		svg
			font-size: 24px
.doc
	cursor: pointer
	width: 210px
	height: 210px
	padding: 30px
	text-align: center
	border-radius: 16px
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1)
	&:hover
		border: 1px solid $Primary600
	span
		display: block
		@include font-face-semibold(16px,	$Primary600)
		line-height: 24px
		margin-bottom: 16px
	img
		width: 100px
		padding: 10px
		object-fit: contain

	+lg
		margin-bottom: 24px
		svg
			font-size: 64px

.helpButtons
	margin: 48px auto 32px
	text-align: center
	>span
		display: block
		line-height: 36px
		@include font-face-medium(16px, $Neutral1000 )
	button
		margin-top: 16px
		border-radius: 4px !important
		font-weight: 500 !important
		@include primaryButton(14px, 40px)
	+lg
		margin: 0 auto 12px
		>span
			@include font-face-medium(14px, $Neutral800 )

.downloadIcon
	margin-right: 12px

.cardsContainer
	width: 100%
	padding: 20px 12px
	border-radius: 16px
	text-align: left
	& :global
		.ant-card-body
			padding: 24px
		.ant-card-head-title
			white-space: normal
			h3
				@include font-face-regular(16px, $Neutral800)
				text-align: left
				margin-bottom: 20px
	img
		height: 50px
		width: auto
	h2
		@include font-face-bold(16px)
		margin: 16px 0
	h4
		@include font-face-medium(16px)
		margin: 12px 0 0 0
	ul
		list-style: none
		margin: 0
		padding: 12px 0 0 0
	li
		@include font-face-medium(16px, $Primary600 )
		cursor: pointer
