@import '../../theme/_theme.sass'

.title
	font-size: 20px
	line-height: 1.2
	margin: 16px 8px 16px 8px
	color: $Neutral800
	font-weight: 600
	span
		color: $Primary600
		margin-right: 8px

.imgContainer
	width: 100%
	padding: 0 24px
	img
		max-height: 500px

.modal
	min-width: 792px
	& :global
		.ant-modal-close-x
			margin: 10px
	*
		font-family: SF Pro

.detailsContainer
	width: 100%
	padding: 24px
	*
		margin: 0
		padding: 0
	& :global
		.ant-divider
			margin: 16px 0
		.ant-row
			width: 100%
			font-size: 16px
			font-weight: 500
			color: $Neutral1000

	h3,h4
		font-size: 16px
		font-weight: 500 !important
		color: $Neutral1000 !important
	h4
		margin: 4px 0
	p
		width: 100%
		font-size: 16px
		color: $Neutral800

.liDos
	list-style: none
	color: $Neutral800
	font-weight: normal
	margin: 12px 6px
	padding-right: 16px
	li
		position: relative
		margin-left: 1em
		line-height: 22px
		margin-bottom: 12px
		span
			position: absolute
			left: -22px
			padding-top: 4px
			color: $Teal700
			font-size: 14px
		 

.liDonts
	@extend .liDos
	li
		span
			color: $Orange700

.helpButton
	width: 100%
	margin-top: 32px
	span, button
		@include font-face-semibold(14px, $Primary600)
	button
		margin-left: 12px
		border-radius: 4px
		border: 2px solid $Primary600
		> span:first-child
			height: 14px
		> span:nth-child(2)
			margin-left: 4px
		padding: 0 14px

		&:hover
			>span
				color: $White !important
			background-color: $Primary600
			border: 2px solid $Primary600
		