@import '../../theme/_theme.sass'

.infoPanel
	width: 400px
	margin-bottom: 8px
	border: solid 1px $Neutral200
	border-radius: 4px !important

	& :global
		.ant-collapse-header
			align-items: center !important
			border-radius: 4px !important
			background: $White
			font-weight: bold !important
			color: $Neutral1000 !important
			svg
				margin-right: 7px
			>div
				height: 100%
				display: block !important
		.ant-collapse-content
			border-radius: 0 0 4px 4px !important
			label
				@include font-face-medium(14px, $Neutral1000)
		.ant-form-item
			margin-bottom: 12px
			*
				border-radius: 4px

.infoCollapse
	border: none
	background: $White

.infoImg
	width: 100%
	padding: 14px

.container
	font-family: SF Pro
.dashedBtns
	line-height: 22px
	span
		font-weight: 600
	span:first-child
		font-size: 12px
	@include ghostButton(14px, 42px)

.removeIcon
	cursor: pointer
	font-size: 21px
	color: $Red500
	margin-top: 12px
	margin-left: 10px

.submitButton
	@include primaryButton(14px, 32px)
	margin-top: 48px
	
