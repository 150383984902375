@import '../../theme/_theme.sass'

.container
  font-family: 'SF Pro'
  width: 100%
  height: 100vh

.authIllustration
  max-width: 512px
  position: fixed
  top: 0
  height: 100%
  display: block
  img
    background: $Neutral200
    display: block
    object-fit: contain
    height: 100vh
    width: auto

.authContainer
  width: 100%
  flex-direction: column
  align-items: center
  img
    margin-top: 10vh
    height: 40px
    margin-bottom: 18px
  h1,h2,h3
    text-align: center
  h2
    a
      margin-left: 8px
      color: $Primary600
    @include font-face-medium(14px)
  +lg
    h1
      margin: 0
      @include font-face-medium(20px, $Neutral900)

.form
  font-family: SF Pro
  margin-top: 32px
  width: 90vw
  max-width: 465px
  p
    @include font-face-medium(14px, $Neutral500)
    margin-top: -12px
  input,span,button[type=submit]
    font-family: SF Pro
    border-radius: 4px
  & :global
    .ant-input, .ant-input-password, .ant-input-group-addon
      border: 2px solid $Neutral200
      background: $White
      &:hover, &:focus
        border-width: 2px !important
    .ant-input, .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled)
      &:hover, &:focus
        border-width: 2px !important
        border-color: $Primary500
        box-shadow: none
    .ant-input-group-addon
      border-right: none
    .ant-select-selector
      margin: -2px
    .ant-select
      border-radius: 4px
      background: $White
    .ant-input:placeholder-shown
      @include font-face-medium(14px, $Neutral500)
    .ant-form-item-explain-error
      line-height: 1
      margin: 8px 0 24px
    .ant-select .ant-select-arrow
      right: 18px !important
  +lg
    width: 100%
    padding: 0 24px

.switchAuth
  display: flex
  justify-content: flex-end
  text-align: flex-end
  line-height: 24px
  margin: -12px 0 24px
  @include font-face-medium(14px, $Neutral600)
  button
    min-width: 50px
    margin-left: 8px
  a
    line-height: 1
    margin-bottom: 12px

.secondaryButton
  text-align: center
  @include font-face-medium(14px, $Primary600)
  line-height: 1

.alerts
  width: 100%
  max-width: 465px
  padding: 16px
  margin-top: 32px
    