$Primary: #1890FF
$PrimaryLight: #1890FF70
$White: #FFFFFF
$Black: #000000
$GreyDarkest: #333333
$GreyDarker: #555555
$GreyDark: #888888
$Grey: #AAAAAA
$GreyLight: #CCCCCC
$GreyLighter: #E4E4E4
$GreyLightest: #F5F5F5
$PaleGrey: #FBFAFC

$Primary1000: #002159
$Primary900: #01337D
$Primary800: #03449E
$Primary700: #0552B5
$Primary600: #0967D2
$Primary500: #2186EB
$Primary400: #47A3F3
$Primary300: #7CC4FA
$Primary200: #BAE3FF
$Primary100: #E6F6FF

$Neutral1000: #1F2933
$Neutral900: #323F4B
$Neutral800: #3E4C59
$Neutral700: #52606D
$Neutral600: #616E7C
$Neutral500: #7B8794
$Neutral400: #9AA5B1
$Neutral300: #CBD2D9
$Neutral200: #E4E7EB
$Neutral100: #F5F7FA

$Red1000: #610316
$Red900: #8A041A
$Red800: #AB091E
$Red700: #CF1124
$Red600: #E12D39
$Red500: #EF4E4E
$Red400: #F86A6A
$Red300: #FF9B9B
$Red200: #FFBDBD
$Red100: #FFE3E3

$Yellow1000: #8D2B0B
$Yellow900: #B44D12
$Yellow800: #CB6E17
$Yellow700: #DE911D
$Yellow600: #F0B429
$Yellow500: #F7C948
$Yellow400: #FADB5F
$Yellow300: #FCE588
$Yellow200: #FFF3C4
$Yellow100: #FFFBEA

$Teal1000: #004440
$Teal900: #016457
$Teal800: #048271
$Teal700: #079A82
$Teal600: #17B897
$Teal500: #2DCCA7
$Teal400: #5FE3C0
$Teal300: #8EEDD1
$Teal200: #C6F7E9
$Teal100: #F0FCF9
$GreenishTeal: #2fd06e
$Green: #28C365

$Orange1000: #841003
$Orange900: #AD1D07
$Orange800: #C52707
$Orange700: #DE3A11
$Orange600: #F35627
$Orange500: #F9703E
$Orange400: #FF9466
$Orange300: #FFB088
$Orange200: #FFD0B5
$Orange100: #FFE8D9

$fadeIn: fadeIn

@mixin adjust-line-height($fontSize)
  line-height: calc(#{$fontSize} + 8px)

@mixin font-face-semibold($fontSize: 12px, $color: $Neutral1000)
  @include adjust-line-height($fontSize)
  font-size: $fontSize
  font-weight: 600
  color: $color

@mixin font-face-regular($fontSize: 12px, $color: $Neutral1000)
  font-size: $fontSize
  font-weight: 400
  color: $color

@mixin font-face-medium($fontSize: 12px, $color: $Neutral1000)
  font-size: $fontSize
  font-weight: 500
  color: $color

@mixin font-face-bold($fontSize: 12px, $color: $Neutral1000)
  font-size: $fontSize
  font-weight: bold
  color: $color

@mixin font-face-light($fontSize: 12px, $color: $Neutral1000, $lineHeight: 1)
  font-size: $fontSize
  font-weight: 300
  color: $color
  line-height: $lineHeight

@mixin box-shadow-20-40
  -webkit-box-shadow: 0px 20px 40px 0px rgba(0,0,0,0.15)
  -moz-box-shadow: 0px 20px 40px 0px rgba(0,0,0,0.15)
  box-shadow: 0px 20px 40px 0px rgba(0,0,0,0.15)

@mixin box-shadow($blur, $spread)
  -webkit-box-shadow: 0px $blur $spread 0px rgba(0,0,0,0.15)
  -moz-box-shadow: 0px $blur $spread 0px rgba(0,0,0,0.15)
  box-shadow: 0px $blur $spread 0px rgba(0,0,0,0.15)

@mixin noselect
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

@mixin primaryButton($fontSize: 16px, $height: 40px, $color: $Primary600)
  border-radius: 4px
  padding: 0 16px
  font-size: $fontSize
  line-height: 1
  font-weight: 600
  height: $height
  background-color: $color
  border-color: $color
  & :global
    .anticon
      height: $fontSize

@mixin dangerButton($fontSize: 16px, $height: 40px)
  border-radius: 4px
  padding: 0 16px
  font-size: $fontSize
  line-height: 1
  font-weight: 600
  height: $height
  & :global
    .anticon
      height: $fontSize

@mixin alertRestyle($fontSize: 16px, $color: $Primary400)
  border-radius: 4px
  border: none !important
  *
    color: $color

@mixin ghostButton($fontSize: 16px, $height: 40px)
  border-radius: 4px
  padding: 0 16px
  font-size: $fontSize
  line-height: 1
  font-weight: 600
  height: $height
  & :global
    .anticon
      height: $fontSize
  svg
    height: $fontSize !important

@mixin defaultButtonWithFill($fontSize: 16px, $height: 40px)
  @include ghostButton($fontSize, $height)
  color: $Primary600
  border: 2px solid $Primary600

=xs
  @media (max-width: 576px)
    @content
=sm
  @media (max-width: 576px)
    @content
=md
  @media (max-width: 768px)
    @content
=lg
  @media (max-width: 992px)
    @content
=xl
  @media (max-width: 1200px)
    @content
=xxl
  @media (max-width: 1600px)
    @content

=debug
  *
    border: solid 1px red

=gradlySteps
  border: 1px solid $Neutral300
  border-radius: 12px
  padding: 15px 18px
  font-family: SF Pro
  > div
    cursor: pointer
  & :global
    .anticon
      font-size: 21px
    .ant-steps-icon
      line-height: 28px
    .ant-steps-item-content
      vertical-align: middle
    .ant-steps-item-title
      font-weight: 500
      font-size: 16px
      line-height: 24px
      &::after
        top: 0
        bottom: 0
        margin: auto
    .ant-steps-item-finish
      opacity: 0.7
      color: $Teal500 !important
      svg
        fill: $Teal500
    .ant-steps-item-container
      display: flex
      align-items: center