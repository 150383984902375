@import '../../theme/_theme.sass'

.modal
	font-family: SF Pro
	&	:global
		.ant-row
			width: 100%
		.ant-modal-header
			display: inline-flex
			width: 100%
			height: 68px
			border-bottom: 2px solid $Neutral200
		.ant-modal-title
			margin: auto 0
		.ant-modal-close
			height: 68px
			width: 58px

.title
	@include font-face-semibold(20px, $Neutral800)
	svg
		margin-right: 4px
		font-size: 18px

.content
	@include font-face-medium(16px, black)
	line-height: 24px
	margin-bottom: 24px

.ctaButton
	@include primaryButton(14px , 36px)
	margin-right: 12px
	padding: 0 8px 0 12px
	svg
		margin-left: 4px

.chatButton
	@include defaultButtonWithFill(16px , 42px)

.title
	@include font-face-medium(20px, $Neutral800)
	b
		@include font-face-semibold(20px, $Neutral800)
	svg
		color: $Primary600
		margin-right: 4px
		font-size: 21px