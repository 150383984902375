@import '../../theme/_theme.sass'

.tagRadio
  flex-flow: row wrap
  margin-left: -12px
  .tagRadioItem
    margin: 4px 12px
    @include font-face-regular(14px, $Neutral500)
    cursor: pointer
    border: 1px solid $Neutral500
    border-radius: 4px
    transition: 0.3s
    margin-top: 8px
    min-width: min-content
    &:hover
      color: $Primary600
      border-color: $Primary600
      box-shadow: 0 0 2px $Primary600
      *
        color: $Primary600
    &[aria-selected='true']
      color: $Primary600
      border-color: $Primary600