@import '../../theme/_theme.sass'

.navbarContainer
  border-top: 3px solid $Primary600
  box-shadow: 0 1px 8px rgba(0,0,0,0.1)
  align-items: center

.journeyBarContainer
  scroll-behavior: smooth
  &::-webkit-scrollbar
    display: none
  & :global
    .ant-badge-count
      transform: translate(-70%, -40%)

.homeContainer
  font-size: 32px
  padding: 0 20px
  color: $Primary600
  span
    cursor: pointer

.userJourneyBarContainer
  border-left: 1px solid #DEDEDE
  border-right: 1px solid #DEDEDE
  align-items: center
  padding-left: 24px
  padding-right: 24px
  width: calc(100% - 152px)
  scroll-behavior: smooth

.nonav
  position: absolute
  z-index: 100
  background-color: #F5F7FA
  padding: 24px 0 0
  img
    display: none
    height: 33px
    object-fit: contain
    position: relative
    margin: auto
    padding-right: 54px
  & :global
    .ant-menu-root
      background-color: #F5F7FA
  +lg
    position: relative
    img
      display: inline-block