@import '../../theme/_theme.sass'

// ------------------------------------------ FILL FORM CUSTOM STEP ------------------------------------------
.fillFormStepContainerIncomplete
  display: block !important
  height: 400px
  width: 100%
  padding-bottom: 40px
  background-size: 128px 128px !important

.fillFormStepContainerProcessing
  width: 100%
  margin: 24px 0
  align-items: flex-start
  .answersContainer
    flex: 0.5
    width: 100%
    margin: 0 32px 40px 0
    flex-direction: column
    align-items: flex-start
    .answerItem
      flex-direction: column
      margin-bottom: 16px
      .question
        @include font-face-medium(14px)
      .answer
        @include font-face-regular(14px)
  img
    flex: 0.5
    margin: 0 0 40px 32px

.fillFormStepContainerReady
  width: 100%
  margin: 24px 0 40px
  align-items: flex-start

.fillFormStepContainerComplete
  width: 100%
  margin: 24px 0 40px
  align-items: flex-start

// ------------------------------------------ CONSUME CONTENT CUSTOM STEP ------------------------------------------
.consumeContentContainerIncomplete
  width: 100%
  margin-bottom: 40px
  align-items: flex-start
  flex-direction: column
  .description
    margin-top: 16px
  .stepFulfillmentView
    margin-top: 24px

.consumeContentContainerComplete
  width: 100%
  margin-bottom: 40px
  align-items: flex-start
  flex-direction: column
  .description
    margin-top: 16px
  .stepFulfillmentView
    margin-top: 24px

// ------------------------------------------ CUSTOM STEP ------------------------------------------
.customStepContainerProcessing
  width: 100%
  margin-bottom: 40px
  align-items: flex-start
  flex-direction: row
  .description
    margin-top: 16px
    flex: 0.5
  .stepFulfillmentView
    margin-top: 24px
  img
    flex: 0.5
    margin: 24px 0 40px 32px

.customStepContainerReady
  width: 100%
  margin-bottom: 40px
  align-items: flex-start
  flex-direction: column
  .description
    margin: 16px 32px 0 0
  .stepFulfillmentView
    margin-top: 24px

.customStepContainerComplete
  width: 100%
  margin-bottom: 40px
  align-items: flex-start
  flex-direction: column
  .description
    margin-top: 16px
  .stepFulfillmentView
    margin-top: 24px


// ------------------------------------------ FULFILLMENT VIEW ------------------------------------------
.content
  flex-direction: column
  width: 100%
  .subTitle
    margin-top: 16px
    @include font-face-medium(14px)
    color: $Neutral1000
    &:first-of-type
      margin-top: 0px
  .attachmentsContainer
    display: grid !important
    grid-template-columns: repeat(auto-fill, minmax(192px, 1fr))
    grid-column-gap: 8px
    grid-row-gap: 8px
    .attachment
      padding: 16px
      border: 1px solid $Neutral200
      box-sizing: border-box
      border-radius: 4px
      cursor: pointer
      align-items: center
      .fileName
        @include font-face-regular(14px)
        color: $Neutral800
      .icon
        font-size: 24px
        margin-right: 8px
  button
    margin-top: 24px
    width: 192px
