@import '../../theme/_theme.sass'

.progressTitle
  align-items: baseline
  .heading
    @include font-face-medium(24px, $Neutral900)
  .stepsLeft
    @include font-face-medium(14px, black)
    margin-left: auto
  .stepsLeftComplete
    @include font-face-semibold(14px)
    color: $Teal600
    margin-left: auto
  +lg
    .heading
      @include font-face-semibold(20px, $Neutral900)
      line-height: 24px
    .stepsLeft
      display: none !important

.progressBar
  margin-top: 16px
  +lg
    display: none