@import '../../theme/_theme.sass'

.container
  background-color: $Neutral100
  height: calc(100vh - 73px)
  overflow: auto
  width: 100%
  padding: 24px 48px
  flex-direction: column
  .childContainer
    width: 100%
    max-width: 1088px
    margin: 32px auto
    flex-direction: column
.requestButton
  background-color: $Primary
  color: $White
  font-weight: 600
  margin-top: 30px
  height: 46px
  border-radius: 6px
  width: 25vw

.requestButton:hover
  background-color: $White
  color: $Primary
  border: 3px solid inner $Primary