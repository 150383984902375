@import '../../theme/_theme.sass'

.errorText
	h1
		@include font-face-bold(24px)
		line-height: 32px
	p
		@include font-face-regular(16px, $Neutral800)
		line-height: 24px
	button
		@include primaryButton(14px, 36px)
		margin-bottom: 48px
	+lg
		padding: 16px
		h1
			font-size: 20px
		p
			font-size: 14px
			
.illustration
	height: 325px
	object-fit: contain
	+lg
		display: flex
		height: 200px
		margin: 0 auto