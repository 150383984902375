@import '../../theme/_theme.sass'

.offerCard
	font-family: SF Pro
	width: 100%
	margin: 12px
	padding: 10px 24px
	border-radius: 4px
	border: solid 2px $Neutral200
	& :global
		.ant-card-body
			padding: 0 !important
		.ant-row
			width: 100%
		.ant-skeleton-paragraph
			margin: 0
			width: 200px
	button
		font-weight: 500 !important
		width: 225px !important
	& :global
		.ant-btn-primary
			@include primaryButton(12px, 32px)
		.ant-btn-default
			@include defaultButtonWithFill(12px, 32px)

.logo
	margin-left: 12px
	width: 100px
	height: 100px
	img
		padding: 20px
		height: 100%
		width: 100%
		object-fit: contain

.detailsList
	list-style: none
	font-family: SF Pro
	font-size: 14px
	line-height: 1
	font-weight: 600
	color: $Black
	margin: 0
	vertical-align: middle
	li
		display: flex
		align-items: center
		padding: 6px 0
		span:nth-child(1)
			font-size: 20px
			color: $Primary600
			font-weight: bold
			padding-right: 12px

.declined
	>div >div >div
		opacity: 0.5
		&:last-child
			opacity: 1
	pointer-events: none

.declinedTag
	font-family: SF Pro
	border-radius: 4px
	align-items: center
	justify-content: center
	justify-self: flex-end
	width: 225px
	@include font-face-medium(16px, $Red500)
	line-height: 40px
	margin-left: auto
	span
		font-size: 21px
		margin-right: 10px
	

.recommended
	border: solid 2px $Teal600
	&:hover
		border-color: $Teal600
	&::after
		content: '★ Top Choice'
		color: $White
		@include font-face-semibold(12px, $White )
		font-family: SF Pro
		line-height: 20px
		position: absolute
		top: 10px
		left: -50px
		padding: 4px 13px
		background-color: $Teal600
		border-radius: 4px

.processing
	pointer-events: none
	opacity: 0.6
	border: solid 2px $Yellow400
	&:hover
		border-color: $Yellow400
	&::after
		content: 'Processing'
		color: $White
		font-family: SF Pro
		font-size: 10px
		font-weight: normal
		font-stretch: normal
		font-style: normal
		line-height: 2
		position: absolute
		top: 10px
		left: -10px
		padding: 0 10px
		background-color: $Yellow400
		border-radius: 4px

.awaiting
	width: 100%
	font-family: SF Pro
	line-height: 1
	h1
		font-size: 32px
		font-weight: 600
		color: $Neutral1000
		margin-bottom: 24px
	h2
		font-size: 16px
		font-weight: normal
		margin: 0

	img
		width: 28rem
