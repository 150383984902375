@import '../../theme/_theme.sass'

.welcomeScreenContainer
	overflow: auto
	display: block !important
	height: 87vh
	max-width: 1344px
	width: 100%
	margin: 0 auto
	margin-top: 24px
	background-color: white
	font-family: SF Pro
	line-height: 1
	& :global
		.ant-row
			width: 100%

.header
	display: inline
	h2
		display: inline
		@include font-face-bold(36px)
		line-height: 42px
		margin: 0
		vertical-align: middle
	img
		height: 42px
		width: 42px
		object-fit: contain
		margin-right: 16px

.welcomeBanner
	width: 80% !important
	margin: 96px auto 60px auto
	button
		@include primaryButton(16px, 48px)
		padding: 0 72px
	

.heroSection
	width: 80% !important
	margin: 0 auto

.welcomeScreen
	height: 100vh
	width: 100%
	background-color: $Neutral100

.welcomePic
	display: block
	width: 332px
	object-fit: contain
	margin: auto
	padding: 20px

.spinnerContainer
	display: block
	height: 65px
	width: 65px

.spinner, .wave
	height: 65px
	width: 65px
	position: absolute

.heroInfo
	padding: 20px
	h1
		margin: 24px 80px 8px 0
		@include font-face-semibold(24px,	$Neutral1000)
		line-height: 36px
	ul
		padding: 0
		width: 350px
	li
		display: inline-flex
		flex-direction: row
		width: 100%
		list-style: none
		margin: 8px
		margin-left: 0
		position: relative
		@include font-face-medium(16px,	$Neutral800)
		line-height: 22px
		svg
			padding: 1.5px
			color: $Teal800
			--fa-secondary-color: #c6f7e9
			--fa-secondary-opacity: 1
			width: 21px
			height: 21px
			margin-right: 16px

.step
	margin: 64px 0
	text-align: center
	span
		display: block
		margin-bottom: 8px
		line-height: 18px !important
		@include font-face-semibold(16px)
	svg
		padding: 0 12px
		color: $Primary600
		font-size: 40px
		position: relative
		top: -20px
		background-color: $White
	>div
		display: block !important
		width: 248px !important
		height: 118px
		border-radius: 24px
		padding: 0 24px
		margin: 0 12px
		border: 2px solid $Neutral200
