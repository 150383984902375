@import '../../theme/_theme.sass'

%tag
  border-radius: 20px
  padding: 6px 12px
  cursor: pointer
  margin: 8px 8px
  align-items: center
    
.tag-select
  flex-wrap: wrap
  padding-bottom: 4px
  margin-left: calc(0px - 8px)
  .tag
    font-weight: 500
    @extend %tag
    border: 1px solid #CCC
    &:hover
      border: 1px solid $Primary600
      box-shadow: 0.5px 0px 4px $Primary600
  .tag-selected
    @extend %tag
    border: 1px solid $Primary600
    background-color: $Primary600
    color: white
