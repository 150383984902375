@import '../../theme/_theme.sass'

.welcomeScreenContainer
	overflow: auto
	display: block !important
	height: 87vh
	max-width: 1344px
	width: 100%
	margin: 0 auto
	margin-top: 24px
	background-color: white
	font-family: SF Pro
	line-height: 1

=ulList
	list-style: none
	li
		display: flex
		margin-bottom: 16px
		svg
			font-size: 21px
			vertical-align: middle
			margin-right: 16px
			color: $Primary600
			--fa-secondary-color: #e6f6ff
			--fa-secondary-opacity: 1
		@include font-face-medium(16px, $Neutral600)
		line-height: 24px

=floatingHeader
	max-width: fit-content
	position: relative
	@include font-face-semibold(16px)
	line-height: 22px
	text-align: center
	background-color: $White
	top: -16px
	padding: 0 8px

.welcomeBanner
	width: 1069px
	margin: 8vh auto 64px auto
	ul
		+ulList
	button
		@include primaryButton(16px, 44px)
		padding: 0 16px
	 
	h2
		@include font-face-bold(20px, $Primary600)
		line-height: 24px
	p
		margin-bottom: 24px
		@include font-face-medium(16px, $Neutral600)
		line-height: 22px
	h1
		@include font-face-bold(32px)
		line-height: 42px
		margin: 24px 0
		img
			margin-right: 16px
			max-height: 42px
			width: auto
			object-fit: contain

.welcomeScreen
	height: calc(100vh - 73px)
	width: 100%
	background-color: $Neutral100

.stepsContainer
	margin: 90px 112px
	border-top: 3px solid $Neutral300
	>span.floatingHead
		color: $Primary600
		font-size: 48px
		margin-left: -61px
		top: -30px

.floatingHead
	+floatingHeader
	border-radius: 4px
	padding: 4px 8px
	

.step
	display: flex
	align-items: center
	flex-flow: column
	max-width: 275px
	margin: 0px 96px 0 0
	>span > svg 
		font-size: 25px
		margin: 4px 0 36px
	&:first-child
		.floatingHead
			color: $Red700
			background-color: $Red100
		>span>svg
			color: $Red400
	&:nth-child(2)
		.floatingHead
			color: $Yellow800
			background-color: $Yellow100
		>span>svg
			color: $Yellow700
	&:nth-child(3)
		.floatingHead
			color: $Teal800
			background-color: $Teal100
		>span>svg
			color: $Teal700

.box
	border: 2px solid $Neutral300
	padding: 24px 34px
	border-radius: 24px
	flex-flow: column
	align-items: center
	> span
		+floatingHeader
		font-weight: bold
		top: -36px
		max-width: 180px
	ul
		+ulList
		margin: -22px 0
		padding: 0