@import '../../theme/_theme.sass'

.container
	max-width: 45%
	margin-bottom: 48px
	font-family: SF Pro !important
	form
		>div>span
			font-size: 26px
			line-height: 48px
			color: $Neutral1000
			+lg
				font-size: 18px
				line-height: 24px
	button
		margin-top: 18px
		@include primaryButton(16px, 40px)
		+lg
			margin-top: 0
		

.insuranceContainer
	@extend .container
	max-width: 94%
	& :global
		.ant-row
			width: 100%
	+lg
		margin: 0

.formText
	display: block !important
	font-family: SF Pro
	font-size: 32px
	line-height: 40px
	letter-spacing: 0.96px
	color: $Neutral500
	text-align: center !important
	>div>div
		display: inline
	svg
		color: $Primary600
		margin-right: 4px
	input
		&::placeholder
			@include font-face-medium(32px, $Primary600)
	+lg
		font-size: 16px
		line-height: 24px
		input
			&::placeholder
				@include font-face-medium(16px, $Primary600)
				line-height: 20px

.naturalSelect
	font-family: SF Pro
	font-size: 32px
	line-height: 40px !important
	min-width: 50px
	border-bottom: 3px dotted $Primary600
	text-underline-offset: 8px
	color: $Neutral1000
	*
		height: 48px
		font-family: SF Pro !important
		font-size: 32px !important
		line-height: 1 !important
		padding: 0 !important
		border: none !important
	& :global
		.ant-select-selection-search
			left: 0 !important
		.ant-select-selection-placeholder
			@include font-face-medium(32px, $Primary600)
	+lg
		min-width: unset !important
		*
			height: 16px !important
			font-size: 16px !important
			
.naturalOptions
	min-width: max-content !important
	& :global
		.ant-select-item-option-content
			font-family: SF Pro
			@include font-face-medium(16px, $Neutral1000)


.numberInput
	font-family: SF Pro
	font-size: 32px
	line-height: 1.5
	width: min-content
	padding: 0
	border-bottom: 3px dotted $Primary600
	&:hover
		border-bottom: 3px dotted $Primary600
	input
		height: 48px
	*
		height: 48px
		font-family: SF Pro !important
		font-size: 32px !important
		line-height: 1 !important
		padding: 0 !important
	+lg
		width: 22px !important
		font-size: 16px
		input
			height: 16px
		*
			height: 16px
			font-family: SF Pro !important
			font-size: 16px !important

.input
	font-family: SF Pro
	font-size: 32px
	line-height: 1
	min-width: 115px
	padding: 0 5px
	border: none
	box-shadow: none
	border-bottom: 3px dotted $Primary600
	&::placeholder
		@include font-face-medium(32px, $Primary600)
	&:hover
		box-shadow: none
		border: none
		border-bottom: 3px dotted $Primary600
	&:focus
		box-shadow: none
		border: none
		border-bottom: 3px dotted $Primary600
	+lg
		font-size: 16px
		&::placeholder
			font-size: 16px

.uniSelect
	@extend .naturalSelect


.datePicker
	padding: 0 4px !important
	& :global
		.ant-picker-input
			input
				font-family: SF Pro
				font-size: 32px
				width: 205px
				line-height: 1
				border: none
				box-shadow: none
				border-bottom: 3px dotted $Primary600
				&[value=""]
					width: 160px
				&::placeholder
					@include font-face-medium(32px, $Primary600)
				+lg
					font-size: 16px
					width: 112px
					&[value=""]
						width: 76px
					&::placeholder
						font-size: 16px
						width: 120px
		.ant-picker-suffix
			display: none
			font-size: 22px !important
		.ant-picker-clear
			opacity: 1
