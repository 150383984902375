@import '../../theme/_theme.sass'

.container
	width: 100%

.useContainer
	width: 100%
	& :global
		.ant-radio-group
			border-radius: 4px
			margin-bottom: 36px
			overflow: hidden

.tags
	justify-content: flex-start
	margin-bottom: 16px
	gap: 4px
	> span
		padding: 4px 8px
		border: none
		border-radius: 20px
		font-size: 12px
		font-weight: 600
		margin-bottom: 8px
	+lg
		justify-content: center

.infoContainer
	padding-right: 68px
	h1
		margin-bottom: 16px
		@include font-face-bold(20px, $Neutral1000)
		line-height: 24px
		span
			@include font-face-bold(20px, $Teal700)
			line-height: 24px
			&:first-of-type::before
				content: "\a"
				white-space: pre
	button
		&:first-of-type
			@include primaryButton(14px, 36px)
			margin-right: 12px
		&:last-of-type
			@include defaultButtonWithFill(14px, 36px)
			padding: 8px 16px 8px 8px
	+lg
		padding: 0
		text-align: center
		h1
			span:first-of-type::before
				

.tContainer
	width: min-content
	margin-bottom: 24px
	min-width: 434px
	padding: 16px
	border-radius: 4px
	border: 1px solid $Neutral300
	h2
		@include font-face-bold(14px, $Neutral800)
		line-height: 20px
		span
			color: $Teal700
	+lg
		min-width: fit-content

.pButtons
	+lg
		justify-content: center
		button
			margin: 12px auto 0 !important

.tRow
	width: 100%
	padding: 4px 4px 8px 8px
	margin-right: 8px
	@include font-face-regular(14px)
	line-height: 20px
	label
		&::after
			content: ':'
			margin-left: 2px
	span
		margin-left: auto

	&:first-of-type
		font-weight: 700
		border-bottom: 1px solid $Neutral300
		margin-bottom: 4px
		padding: 0 0px 10px 8px

	&:last-of-type
		margin-top: 12px
		margin-right: 0
		padding: 4px 8px
		border-radius: 4px
		background-color: $Teal100
		@include font-face-semibold(14px, $Teal800)
		line-height: 24px
		
.priceCollapse
	width: 100%
	border-bottom: 1px solid $Neutral300
	& :global
		.ant-collapse-content-box
			padding: 0 8px 0 24px !important
		.ant-collapse-content-active
			&:last-of-type
				padding-bottom: 8px
		.ant-collapse-header
			cursor: pointer
			margin: 12px 8px
			@include font-face-semibold(14px, $Neutral800)
			color: $Neutral800 !important
			padding: 0 !important
			&[aria-expanded="true"]
				margin-bottom: 4px
			.anticon
				margin-right: 4px !important
			> div
				margin: auto
			label
				cursor: pointer
			

.subRow
	width: 100%
	line-height: 20px
	margin-bottom: 4px
	label
		line-height: 20px
		&::after
			content: ':'
			margin-left: 2px
	span
		margin-left: auto


.breakdown
	border-radius: 12px
	border: 2px solid $Teal600
	overflow: hidden
	padding-bottom: 28px

.lineItem
	padding: 0 36px
	img
		width: 96px
		height: auto
		object-fit: contain

.bRow
	@include font-face-medium(12px, $Neutral800)
	line-height: 18px
	

.btitle
	padding-left: 16px
	h1
		@include font-face-semibold(14px)
		line-height: 20px
		margin-bottom: 4px

.lineItemText
	height: revert
	align-self: center	

.downArrow
	width: 0
	height: 34px
	margin: 12px auto
	border-left: 2px solid $Teal600
	&::after
		content: '↓'
		font-size: 24px
		margin-top: auto
		line-height: 1
		margin-bottom: -5px
		margin-left: -10.75px
		color: $Teal600

.totalCost
	padding: 8px 36px
	@include font-face-bold(16px, $White)
	line-height: 24px
	background-color: $Teal600
	margin-bottom: 24px

.otherPlans
	margin-top: 64px
	width: min-content
	.header
		display: inline-flex
		width: 100%
		h1
			@include font-face-semibold(16px, $Neutral800)
			line-height: 24px
		button
			margin-left: auto
			@include font-face-semibold(14px, $Primary600)
			height: 24px
			padding: 0
	h2
		@include font-face-medium(12px, $Neutral1000)
		line-height: 18px
		&:before
			content: '* '
			color: $Red500
	+lg
		text-align: center
		width: 100%
		.header
			display: block !important

.pCards
	gap: 24px
	margin: 16px 0
	flex-wrap: nowrap
	+lg
		flex-wrap: wrap
		gap: 16px
		
		
.planCard
	display: flex
	flex-direction: column
	width: 150px
	height: 110px
	padding: 8px 12px
	border-radius: 4px
	text-align: start
	box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1)
	border: solid 1px $Neutral200
	h1
		@include font-face-medium(14px, $Neutral800)
		line-height: 20px
	> span
		@include font-face-bold(14px, $Neutral1000)
		line-height: 20px
		margin-top: 4px
		&:after
			content: ' *'
			color: $Red500
	+lg
		margin: 8px auto
	
	button
		margin: auto auto 0 0
		@include font-face-semibold(12px, $Primary600)
		& :global
			.anticon-right
				margin-left: 4px
				font-size: 9px
		height: 18px
		padding: 0
		