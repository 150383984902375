@import '../../theme/_theme.sass'

.payment
	border-radius: 4px
	background-color: $Neutral100
	width: 80%
	margin: 24px auto
	padding: 64px 48px
	+sm
		background-color: $White
		
.planInfo
	line-height: 1
	> span
			@include font-face-bold(16px, $Teal800)
	> h1
		@include font-face-bold(20px, $Neutral1000)
		margin: 12px 0 4px 0
	> h2
		@include font-face-semibold(12px, $Neutral800)
		margin: 0 0 32px 0
	+lg
		> span
			font-size: 14px
		> h1
			font-size: 16px
			margin: 0
		> h2
			font-size: 12px
			margin-bottom: 16px
	+sm
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.05)
		background-color: $Neutral100
		border-radius: 12px
		padding: 24px
		> span
			@include font-face-semibold(16px, $Primary600)
		> h2
			@include font-face-semibold(12px, $Neutral800)
			padding-bottom: 8px
			margin: 0 0 16px 0
			border-bottom: 1px solid $Neutral300
	

.payDeposit, .processingDeposit, .invoiceFailed
	height: 100%
	>div> svg
		width: 100%
		font-size: 42px
		margin-bottom: 16px
		color: $Teal800
	h1
		@include font-face-bold(22px, $Neutral1000)
		margin-bottom: 8px
	+sm
		justify-content: center
		svg
			margin-top: 24px
			font-size: 24px
			margin-bottom: 0
			color: $Teal800
		h1
			font-size: 16px
			margin: 0
.invoiceFailed
	>div
		flex-direction: column
		text-align: center
		> svg
			color: $Red800

.depositConfirmed
	height: 100%
	> div > span >  svg
		font-size: 42px
		margin-bottom: 16px
		color: $Teal800
	h1
		width: 100%
		text-align: center
		@include font-face-semibold(28px, $Neutral1000)
	+sm
		padding-top: 24px
		justify-content: center
		svg
			margin-top: 24px
			font-size: 24px
			margin-bottom: 0
			color: $Teal800
		h1
			font-size: 16px
	+lg
		h1
			font-size: 24px
		svg
			font-size: 36px
	
.liReasons
	list-style: none
	padding: 0
	margin: 0
	li
		@include font-face-medium(12px, $Neutral800)
		position: relative
		line-height: 22px
		margin: 0 12px 4px 20px
		span
			position: absolute
			left: -20px
			color: $Teal600
		svg
			padding: 1px
			margin-top: 5px
			width: 12px
			height: 12px

.invoiceButton
	@include primaryButton(14px, 34px)

.invoiceButtonDisabled
	@include primaryButton(14px, 34px)
	background-color: $Neutral200 !important
	border-color: $Neutral200 !important
	color: $Neutral600 !important

.priceTable
	width: 100%
	margin: 24px 0
	@include font-face-medium(14px, $Neutral800)
	h2
		@include font-face-semibold(14px, $Neutral800)
		line-height: 1
	h1
		@include font-face-bold(16px, $Neutral800)
		line-height: 1
	+lg
		margin: 16px 0
	+sm
		font-size: 12px
		h2
			line-height: 1
			font-size: 12px
		h1
			font-size: 14px
