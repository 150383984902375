@import '../../theme/_theme.sass'

.popUp
  top: 56px !important
  .iframe
    width: 100%
    border: 0
    height: 80vh
    background-size: 128px 128px !important
  .closeButton
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 3px 6px rgba(0, 0, 0, 0.15)
    color: $Primary600
.spanLink
  cursor: pointer
  text-decoration: underline
  color: $Primary600
